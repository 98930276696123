import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';

interface ExampleComponentProps {
    autoGenerateExample: (generate_type: string) => Promise<any>;
    generate_type: string;
}


function customFormat(obj: any, indent: number = 2, compactArrays: boolean = true): string {
    return JSON.stringify(
        obj,
        (key, value) => {
            if (compactArrays && Array.isArray(value)) {
                return JSON.stringify(value);
            }
            return value;
        },
        indent
    ).replace(/"\[(.*?)\]"/g, (match, p1) => {
        const compactArray = p1.split(',').map((num: string) => num.trim()).join(', ');
        return `[${compactArray}]`;
    });
}


function ExampleComponent({ autoGenerateExample, generate_type }: ExampleComponentProps) {
    const [open, setOpen] = useState(false);
    const [responseData, setResponseData] = useState<string>('');

    const handleFetch = async () => {
        const result = await autoGenerateExample(generate_type);

        if (typeof result === 'string') {
            alert(result); // 如果是失败消息，直接弹窗提示
        } else {
            // setResponseData(JSON.stringify(result, null, 2)); // 格式化 JSON 数据
            setResponseData(customFormat(result, 2));
            setOpen(true); // 打开对话框显示数据
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button variant="text" onClick={handleFetch}>
                Generate Example
            </Button>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>Generated Example</DialogTitle>
                <DialogContent>
                    <Typography component="pre" style={{ whiteSpace: 'pre-wrap' }}>
                        {responseData}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ExampleComponent;
