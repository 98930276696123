import { Box, CircularProgress } from "@mui/material";
import { useIsFetching } from '@tanstack/react-query';

function FetchingIndicator() {
    const isFetching = useIsFetching();
    if (isFetching) {
        return <Box sx={{ position: 'fixed', bottom: "0", right: "0", padding: 0, zIndex: 1350 }}>
            <CircularProgress sx={{ margin: 0, padding: 1 }} size="1.2rem" />
        </Box>
    } else {
        return <></>
    }
}
export default FetchingIndicator;