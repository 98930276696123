import { Button, MenuItem, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import { DescData, FieldDesc, FieldType, getFieldTypeKey } from "./common";


function createFieldDescDataFromSpec(result: {
    [key: string]: FieldDesc
}, currentKey: string, typeSpec: any, type: FieldType, skipRoot: boolean = false) {
    // console.log(currentKey, typeSpec, type, skipRoot);
    if (!skipRoot) {
        if (currentKey in result) {
            throw new Error(`key ${currentKey} already exists`);
        }
        if (currentKey === '') {

            result[currentKey] = {
                key: currentKey,
                keyDisplay: "Summary",
                data: {},
                obsolete: false,
                type: type,
                spec_type: typeSpec,
            };
        } else {
            result[currentKey + ".$text"] = {
                key: currentKey + ".$text",
                keyDisplay: currentKey,
                data: {},
                obsolete: false,
                type: type,
                spec_type: typeSpec,
            };
            result[currentKey + ".$example"] = {
                key: currentKey + ".$example",
                keyDisplay: currentKey + " Example",
                data: {},
                obsolete: false,
                type: type,
                spec_type: typeSpec,
            };
        }
    }
    if (typeof (typeSpec) === 'string') {
        // nothing to do
    } else {
        if (typeof (typeSpec) !== 'object') {
            throw new Error(`invalid spec type ${typeSpec}`);
        }
        // if typeSpec is a special object (dict only single element starting with $)
        let keys = Object.keys(typeSpec);
        if (keys.length === 1 && keys[0].startsWith('$')) {
            let specType = keys[0].slice(1);
            let specSubType = typeSpec[keys[0]];
            if (specType === 'dict') {
                createFieldDescDataFromSpec(result, currentKey + ".$key", specSubType['key'], type);
                createFieldDescDataFromSpec(result, currentKey + ".$value", specSubType['value'], type);
            } else if (specType === 'list') {
                createFieldDescDataFromSpec(result, currentKey + ".$item", specSubType, type);
            } else if (specType === 'tensor') {
                // nothing to do
            } else if (specType === 'optional') {
                createFieldDescDataFromSpec(result, currentKey, specSubType, type, true);
            } else if (specType === 'struct') { // struct
                for (let key in specSubType) {
                    if (key.startsWith('$')) {
                        throw new Error(`invalid struct field name ${key}`);
                    }
                    createFieldDescDataFromSpec(result, currentKey + "." + key, specSubType[key], type);
                }
            } else if (specType === 'enum') {
                // nothing to do
            } else if (specType === 'tuple') {
                for (let i = 0; i < specSubType.length; i++) {
                    createFieldDescDataFromSpec(result, currentKey + `.$item${i}`, specSubType[i], type);
                }
            } else if (specType === 'optional') {
                // nothing to do
            }
            else {
                throw new Error(`invalid spec type ${specType}`);
            }
        } else {
            // a normal struct
            for (let key in typeSpec) {
                if (key.startsWith('$')) {
                    throw new Error(`invalid struct field name ${key}`);
                }
                createFieldDescDataFromSpec(result, currentKey + "." + key, typeSpec[key], type);
            }
        }
    }
}

function constructFieldDescState(description: any, inputs: any, outputs: any, current_field_desc_data: { [key: string]: { [key: string]: string } }): DescData {
    const all_fields: {
        inputs: { [key: string]: FieldDesc },
        outputs: { [key: string]: FieldDesc },
    } = {
        inputs: {},
        outputs: {},
    };

    createFieldDescDataFromSpec(all_fields.inputs, '', inputs, 'input');
    if (outputs) {
        createFieldDescDataFromSpec(all_fields.outputs, '', outputs, 'output');
    }
    return {
        description: {
            data: description || {},
            obsolete: false,
            type: "description",
            spec_type: null,
            key: '',
            keyDisplay: 'Description',
        },
        inputs: Object.values(all_fields.inputs),
        outputs: Object.values(all_fields.outputs),
    }
}

function updateFieldDesc(desc: FieldDesc, lang: string, value: string, inplace: boolean = false) {
    let newState: FieldDesc = inplace ? desc : structuredClone(desc);
    const oldValue = desc.data[lang] ? desc.data[lang].value : '';
    const oriValue = desc.data[lang] ? desc.data[lang].oriValue : '';
    if (oldValue === value) {
        return newState;
    } else {
        newState.data[lang] = {
            changed: value !== oriValue,
            value: value,
            oriValue: oriValue,
        };
        return newState;
    }
}
function getDescValue(state: DescData, field: string, lang: string) {
    let result = {
        changed: false,
        value: '',
        oriValue: '',
    }
    const { fieldType, fieldKey } = getFieldTypeKey(field);
    if (fieldType === 'description') {
        if (state.description.data[lang]) {
            result = state.description.data[lang];
        }
    } else {
        const ref = fieldType === 'input' ? state.inputs : state.outputs;
        const field = ref.find((f) => f.key === fieldKey);
        if (!field) {
            throw new Error(`unknown ${fieldType} field ${fieldKey}`);
        }
        if (field.data[lang]) {
            result = field.data[lang];
        }
    }
    result.value = result.value || '';
    result.oriValue = result.oriValue || '';
    result.changed = result.changed || false;
    return result;
}

function getFieldDisplayName(state: DescData, field: string) {
    const { fieldType, fieldKey } = getFieldTypeKey(field);
    if (fieldType === 'description') {
        return state.description.keyDisplay;
    } else {
        const ref = fieldType === 'input' ? state.inputs : state.outputs;
        const field = ref.find((f) => f.key === fieldKey);
        if (!field) {
            throw new Error(`unknown ${fieldType} field ${fieldKey}`);
        }
        return field.keyDisplay;
    }
}

function updateFieldDescState(
    state: DescData,
    field: string,
    lang: string,
    value: string): DescData {
    let newState = { ...state }; // shallow copy
    const { fieldType, fieldKey } = getFieldTypeKey(field);
    if (fieldType === 'description') {
        newState.description = updateFieldDesc(newState.description, lang, value);
        return newState;
    } else {
        var ref: FieldDesc[];
        if (fieldType === 'input') {
            ref = newState.inputs;
        }
        else if (fieldType === 'output') {
            ref = newState.outputs;
        }
        else {
            throw new Error(`invalid field type ${fieldType}`);
        }
        let field = ref.find((f) => f.key === fieldKey);
        if (!field) {
            throw new Error(`unknown ${fieldType} field ${fieldKey}`);
        } else {
            updateFieldDesc(field, lang, value, true);
        }
        return newState;
    }
}

function ProcessorDetails() {
    const auth = useAuth();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const [env, type, group, name, version] = id!.split('/');
    const { isPending, error, data } = useQuery({
        queryKey: ['processors', 'detail', type, group, name, version],
        queryFn: async () => {
            const res = await fetch(`/api/v1/processor/${env}/${type}/${group}/${name}/${version}`, {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            });
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to fetch processor (${txt})`);
            }
            return await res.json();
        },
        retry: 3,
    });

    const [column1Lang, setColumn1Lang] = useState('CHN');
    const [column2Lang, setColumn2Lang] = useState('ENG');
    const [fieldDescState, setFieldDescState] = useState<DescData | null>(null);

    if (isPending) {
        return <>Loading...</>;
    } else if (error) {
        return <>Error: {error.message}</>;
    } else if (data == null) {
        return <>No data</>;
    }

    function anyChanged(lang: string) {
        if (fieldDescState == null) {
            return false;
        }
        if (fieldDescState.description.data[lang]?.changed) {
            return true;
        }
        for (let i = 0; i < fieldDescState.inputs.length; i++) {
            if (fieldDescState.inputs[i].data[lang]?.changed) {
                return true;
            }
        }
        for (let i = 0; i < fieldDescState.outputs.length; i++) {
            if (fieldDescState.outputs[i].data[lang]?.changed) {
                return true;
            }
        }
        return false;
    }


    const handleColumn1LangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!anyChanged(column1Lang) || window.confirm('Changes not saved, discard?')) {
            setColumn1Lang(event.target.value);
        }
    };

    const handleColumn2LangChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!anyChanged(column2Lang) || window.confirm('Changes not saved, discard?')) {
            setColumn2Lang(event.target.value);
        }
    };
    const { info, field_desc } = data;
    const { basic, k8s } = info;
    const initFieldDescState: DescData = constructFieldDescState(
        basic.description,
        basic.inputs,
        basic.outputs,
        field_desc
    );
    if (fieldDescState == null) {
        setFieldDescState(initFieldDescState);
    }

    function updateField(lang: string, key: string, value: string) {
        console.log(`updating: ${key} ${lang} ${value}`)
    }

    function _onTextChange(lang: string, key: string, value: string) {
        if (fieldDescState == null) {
            return;
        }
        const newState = updateFieldDescState(
            fieldDescState!,
            key,
            lang,
            value,
        );
        setFieldDescState(newState);
    }

    function _textField(lang: string, key: string) {
        if (fieldDescState == null) {
            return <></>;
        }
        const { changed, value, oriValue } = getDescValue(fieldDescState!, key, lang);
        return (<Stack>
            <TextField
                value={value}
                onChange={(e) => _onTextChange(lang, key, e.target.value)}
                multiline
                fullWidth
                minRows={2}
            />
            {changed && <Stack direction={"row"}>
                <Button onClick={() => updateField(lang, key, value)}>Save</Button>
                <Button onClick={() => {
                    if (window.confirm('Are you sure to discard the changes?')) {
                        _onTextChange(lang, key, oriValue);
                    }
                }}>Cancel</Button>
            </Stack>}
        </Stack>)
    }

    function _multiLangTextRow(key: string) {
        let label = key;
        if (fieldDescState != null) {
            label = getFieldDisplayName(fieldDescState, key);
        }

        return (
            <TableRow key={key}>
                <TableCell>{label}</TableCell>
                <TableCell>{_textField(column1Lang, key)}</TableCell>
                <TableCell>{_textField(column2Lang, key)}</TableCell>
            </TableRow>
        );
    }
    function _divider(text: string = "&nbsp;") {
        return (
            <TableRow>
                <TableCell colSpan={3}><b>{text}</b></TableCell>
            </TableRow>
        );
    }
    return (
        <div>
            <div>
                <TextField label="Type" value={basic.type} variant="outlined" fullWidth disabled />
                <TextField label="Group" value={basic.group} variant="outlined" fullWidth disabled />
                <TextField label="Name" value={basic.name} variant="outlined" fullWidth disabled />
                <TextField label="Version" value={basic.version} variant="outlined" fullWidth disabled />
                <TextField
                    label="Visibility"
                    value={basic.visibility || ''}
                    variant="outlined"
                    fullWidth
                    select
                    disabled={!basic.visibility}
                >
                    <MenuItem value="">Default</MenuItem>
                    <MenuItem value="public">Public</MenuItem>
                    <MenuItem value="private">Private</MenuItem>
                </TextField>
            </div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Field</TableCell>
                            <TableCell>
                                <TextField
                                    select
                                    label="Language"
                                    value={column1Lang}
                                    onChange={handleColumn1LangChange}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value="CHN">CHN</MenuItem>
                                    <MenuItem value="ENG">ENG</MenuItem>
                                </TextField>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    select
                                    label="Language"
                                    value={column2Lang}
                                    onChange={handleColumn2LangChange}
                                    variant="outlined"
                                    size="small"
                                >
                                    <MenuItem value="CHN">CHN</MenuItem>
                                    <MenuItem value="ENG">ENG</MenuItem>
                                </TextField>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_multiLangTextRow('description/')}
                        {_divider("Inputs")}
                        {fieldDescState?.inputs.filter((f) => !f.obsolete).map((f) => _multiLangTextRow('input/' + f.key))}
                        {_divider("Outputs")}
                        {fieldDescState?.outputs.filter((f) => !f.obsolete).map((f) => _multiLangTextRow('output/' + f.key))}
                        {_divider("Inputs (obsolete)")}
                        {fieldDescState?.inputs.filter((f) => f.obsolete).map((f) => _multiLangTextRow('input/' + f.key))}
                        {_divider("Outputs (obsolete)")}
                        {fieldDescState?.outputs.filter((f) => f.obsolete).map((f) => _multiLangTextRow('output/' + f.key))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

export default ProcessorDetails;
