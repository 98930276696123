import * as React from 'react';

type ConfigType = {
    oidcClientId: string,
    oidcAuthority: string,
    oirdRedirectUri: string
}
interface ConfigContextType {
    config?: ConfigType;
};
const configContext = React.createContext<ConfigContextType | undefined>(undefined);

export const useConfig = () => {
    return React.useContext(configContext)!;
};

export function ProvideConfig({ children }: { children: React.ReactNode }) {
    const config = useProvideConfig();
    return <configContext.Provider value={config}>{children}</configContext.Provider>;
}

function useProvideConfig() {
    const [config, setConfig] = React.useState<ConfigType | undefined>(undefined);

    React.useEffect(() => {
        async function fetchConfig() {
            try {
                const response = await fetch('/web-config');
                if (!response.ok) {
                    throw new Error('Failed to fetch config');
                }
                const data = await response.json();
                setConfig(data);
            } catch (error) {
                console.error('Error fetching config:', error);
            }
        }

        fetchConfig();
    }, []);

    const result: ConfigContextType = {
        config: config,
    };
    return result;
}
