"use client"

import React, {useRef, useState, useCallback, useEffect, useLayoutEffect } from 'react';
import ReactFlow, {
  Node,
  Edge,
  ConnectionLineType,
  Panel,
  useNodesState,
  useEdgesState,
  Controls,
  Handle,
  Position,
  MarkerType,
  useViewport
} from 'reactflow';
import 'reactflow/dist/style.css';
import { Paper, Typography, Box, Card, CardContent, Button } from '@mui/material';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { useQuery } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "react-oidc-context";


interface CustomNodeData {
  label: string;
  color: string;
  isHovered?: boolean;
}

const generateFlowData = (
  data: Record<string, any>,
  containerWidth: number,
  containerHeight: number
): { nodes: Node<CustomNodeData>[], edges: Edge[] } => {
    const nodes: Node<CustomNodeData>[] = [];
    const edges: Edge[] = [];
    const levels: Record<string, number> = {};
    const nodesByLevel: Record<number, string[]> = {};

    const inputNode = Object.keys(data).find(key => 
        !Object.values(data).some(nodeData => 
            Array.isArray(nodeData.sub_nodes) && nodeData.sub_nodes.includes(key)
        )
    ) || '';

    const calculateLevels = (nodeId: string, level: number = 0) => {
        if (levels[nodeId] === undefined || level < levels[nodeId]) {
            levels[nodeId] = level;
            if (!nodesByLevel[level]) nodesByLevel[level] = [];
            nodesByLevel[level].push(nodeId);
        }
        if (Array.isArray(data[nodeId].sub_nodes)) {
            data[nodeId].sub_nodes.forEach((childId: string) => calculateLevels(childId, level + 1));
        }
    };

    calculateLevels(inputNode);

    const maxLevel = Math.max(...Object.keys(nodesByLevel).map(Number));
    const levelSpacing = 2 * containerWidth / (maxLevel + 1) + 10;

    Object.entries(nodesByLevel).forEach(([level, nodeIds]) => {
        const numNodes = nodeIds.length;
        const verticalSpacing = 3 * containerHeight / (numNodes + 1) + 30;

        nodeIds.forEach((nodeId, index) => {
            const nodeData = data[nodeId];
            const color = nodeData.node_info.completed
                ? '#A5D6A7'
                : nodeData.node_info.failed
                ? '#FF4500'
                : '#FFFFFF';

            nodes.push({
                id: nodeId,
                position: { 
                    x: Number(level) * levelSpacing,
                    y: (index + 1) * verticalSpacing,
                },
                data: { label: nodeId, color },
                type: 'custom',
            });
        });
    });

    Object.entries(data).forEach(([nodeId, nodeData]) => {
        if (Array.isArray(nodeData.sub_nodes)) {
            nodeData.sub_nodes.forEach((childId: string) => {
                edges.push({
                    id: `${nodeId}-${childId}`,
                    source: nodeId,
                    target: childId,
                    type: 'simplebezier',
                    markerEnd: { type: MarkerType.ArrowClosed },
                });
            });
        }
    });

    return { nodes, edges };
};


const CustomNode: React.FC<{ data: CustomNodeData }> = ({ data }) => {
  return (
    <Paper
      elevation={8}
      sx={{
        padding: 2,
        minWidth: 130,
        borderRadius: '20px',
        textAlign: 'center',
        backgroundColor: data.color,
        transition: 'transform 0.2s',
        transform: data.isHovered ? 'scale(1.1)' : 'scale(1)',
      }}
    >
      <Handle type="target" position={Position.Left} /> 
      <Handle type="source" position={Position.Right} /> 
      <Typography variant="body2">{data.label}</Typography>
    </Paper>
  );
};

const nodeTypes = {
  custom: CustomNode,
};

interface RenderNodeInfoProps {
  nodeData: NodeData;
}

interface NodeData {
  id: string;
  workflow_id: string;
  completed: boolean;
  failed: boolean;
  reason: string | null;
  reason_public: string | null;
  create_time: string;
  update_time: string;
  handler_info: Record<string, any>;
  metadata: Record<string, any>;
}

const RenderNodeInfo: React.FC<RenderNodeInfoProps> = ({ nodeData }) => {
  return (
    <>
      {Object.entries(nodeData).map(([key, value]) => {
        let displayValue;
      
        // 判断 value 是否为时间字符串
        if (typeof value === 'string' && value.includes('T')) {
          displayValue = new Date(value).toLocaleString();
        } else if (typeof value === 'object' && value !== null) {
          // 如果是对象，则递归显示
          displayValue = (
            <ul style={{margin: '0px'}}>
              {Object.entries(value).map(([subKey, subValue]) => (
                <li key={subKey}>
                  <strong>{subKey}:</strong> {typeof subValue === 'object' ? JSON.stringify(subValue) : String(subValue)}
                </li>
              ))}
            </ul>
          );
        } else {
          displayValue = String(value); // 其他类型直接转为字符串
        }
      
        return (
          <Typography key={key} variant="body2">
            <strong>{key}</strong>: {displayValue}
          </Typography>
        );
      })}
    </>
  );
};

function WorkflowrunGraph() {
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerWidth, setContainerWidth] = useState<number | null>(null);
    const [containerHeight, setContainerHeight] = useState<number | null>(null);
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState([]);
    const [hoveredNodeId, setHoveredNodeId] = useState<string | null>(null);
    const [clickedNodeId, setClickedNodeId] = useState<string | null>(null);
    const [subWorkflowId, setSubWorkflowId] = useState<string | null>(null);
    const [cardInfo, setCardInfo] = useState<{ x: number; y: number; nodeData: any } | null>(null);

    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const env = params.get('env');
    const workflow_run_id = params.get('workflow_run_id');
    const auth = useAuth();

    const { isPending, error, data } = useQuery({
      queryKey: [],
      queryFn: async () => {
          const res = await fetch(`/api/v1/get_workflow_run_task/${env}/${workflow_run_id}`, {
              headers: {
                  'Authorization': 'Bearer ' + auth.user!.access_token,
              }
          });
          if (!res.ok) {
              const txt = await res.text();
              throw new Error(`Failed to get workflow run info (${txt})`);
          }
          return await res.json();
      },
      retry: 3,
    });

    useLayoutEffect(() => {
      const cachedWidth = localStorage.getItem('containerWidth');
      const cachedHeight = localStorage.getItem('containerHeight');
    
      if (cachedWidth && !isNaN(Number(cachedWidth))) {
        setContainerWidth(Number(cachedWidth));
      }
      if (cachedHeight && !isNaN(Number(cachedHeight))) {
        setContainerHeight(Number(cachedHeight));
      }

      const updateDimensions = () => {
        if (containerRef.current) {
          const width = containerRef.current.offsetWidth;
          const height = containerRef.current.offsetHeight;
          setContainerWidth(width);
          setContainerHeight(height);
          localStorage.setItem('containerWidth', width.toString());
          localStorage.setItem('containerHeight', height.toString());
        }
      };
    
      if (containerRef.current) {
        updateDimensions();
        const resizeObserver = new ResizeObserver(updateDimensions);
        resizeObserver.observe(containerRef.current);
    
        return () => resizeObserver.disconnect();
      }
    }, []);

    useEffect(() => {
      // console.log("Data:", data);
      console.log("containerWidth:" + containerWidth)
      console.log("containerHeight:" + containerHeight)
      if (data && containerWidth && containerHeight) {
          const { nodes: initialNodes, edges: initialEdges } = generateFlowData(data, containerWidth, containerHeight);
          setNodes(initialNodes);
          setEdges(initialEdges);
      }
    }, [data, containerWidth, containerHeight, setNodes, setEdges]);

    const onNodeClick = useCallback((event: React.MouseEvent, node: Node) => {
      setClickedNodeId(node.id);
      const { left, top } = event.currentTarget.getBoundingClientRect();
      setSubWorkflowId(data[node.id]?.node_info?.sub_workflow_id)
      setCardInfo({
        x: left,
        y: top,
        nodeData: data[node.id]?.node_info, // 展示该节点的 `node_info` 数据
      });
    }, [data]);

    const getEdgeStyle = (edge: Edge) => {
      return edge.source === clickedNodeId || edge.target === clickedNodeId
        ? { stroke: 'blue', strokeWidth: 2 }
        : {};
    };


    const get_input_or_output_data = async () => {
    
      try {
        if (clickedNodeId === 'input'){
            var req_node_url = `/api/v1/workflow/get_workflow_input_data?env=${env}&workflow_id=${workflow_run_id}`
        }
        else if (clickedNodeId === 'output'){
            var req_node_url = `/api/v1/workflow/get_workflow_output_data?env=${env}&workflow_id=${workflow_run_id}`
        }
        else{
            var req_node_url = `/api/v1/workflow/task/inputs?env=${env}&workflow_id=${workflow_run_id}&task_id=${cardInfo?.nodeData.id}`
        }
        const response = await fetch(req_node_url, {
          headers: {
            'Authorization': 'Bearer ' + auth.user!.access_token,
          }
        });
    
        if (!response.ok) {
          const errorText = await response.text();
          alert(`Failed to get workflow input or output data (${errorText})`);
          throw new Error(`Failed to get workflow input or output data (${errorText})`);
        }
    
        // 获取响应的二进制内容
        const blob = await response.blob();
    
        // 创建下载链接并触发下载
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
    
        // 根据后端文件名定义下载文件名（例如 ZIP 文件名）
        if (clickedNodeId === 'input'){
          a.download = `workflow_input_${workflow_run_id}:${cardInfo?.nodeData.id}.zip`;
        }
        else if (clickedNodeId === 'output'){
          a.download = `workflow_onput_${workflow_run_id}:${cardInfo?.nodeData.id}.zip`;
        }
        else{
          a.download = `workflow_input_${workflow_run_id}:${cardInfo?.nodeData.id}.pkl`;
        }
        document.body.appendChild(a);
        a.click();
    
        // 清理 URL 对象
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      } catch (error) {
        console.error("Error downloading input data:", error);
      }
    };
    

    const enter_sub_workflow = () => {
      // navigate(`/workflowRunFlow?env=${env}&workflow_run_id=${subWorkflowId}`, { replace: true });
      window.location.assign(`/workflowRunFlow?env=${env}&workflow_run_id=${subWorkflowId}`);
    } 

    const onNodeMouseEnter = useCallback((_: React.MouseEvent, node: Node<CustomNodeData>) => {
      setHoveredNodeId(node.id);
    }, []);

    const onNodeMouseLeave = useCallback(() => {
      setHoveredNodeId(null);
    }, []);

    if (isPending) {
      return <div>Loading...</div>;
    } else if (error) {
      return <div>Error: {error.message}</div>;
    } else if (data == null) {
      return <>No data</>;
    }

    return (
      <div ref={containerRef} style={{ width: '100%', height: '100vh', backgroundColor: 'background.default' }}>
        <ReactFlow
          nodes={nodes.map(node => ({
            ...node,
            data: {
              ...node.data,
              isHovered: node.id === hoveredNodeId,
            },
          }))}
          edges={edges.map(edge => ({ ...edge, style: getEdgeStyle(edge) }))}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onNodeClick={onNodeClick}
          onNodeMouseEnter={onNodeMouseEnter}
          onNodeMouseLeave={onNodeMouseLeave}
          connectionLineType={ConnectionLineType.SmoothStep}
          nodeTypes={nodeTypes}
          fitView
        >
          <Controls />
          <Panel position="top-left">
            <Paper elevation={3} sx={{ padding: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
              <AccountTreeIcon color="primary" />
              <Typography variant="h6">Process Flow Graph</Typography>
            </Paper>
          </Panel>
        </ReactFlow>
        
        {cardInfo && (
          <Card
            sx={{
              position: 'absolute',
              left: cardInfo.x,
              top: cardInfo.y,
              padding: 1,
              backgroundColor: 'white',
              zIndex: 1000,
              boxShadow: clickedNodeId ? 6 : 24, // 根据节点点击状态调整阴影
              transition: 'box-shadow 0.3s ease', // 添加过渡效果
            }}
            onMouseLeave={() => setCardInfo(null)}
          >
            <CardContent>
              <Typography variant="subtitle1">Node Info:</Typography>
              <RenderNodeInfo nodeData={cardInfo.nodeData} />
              {(((cardInfo.nodeData.completed || cardInfo.nodeData.failed) && clickedNodeId != 'output') || (clickedNodeId == 'input')) && (
              <Button 
                variant="contained" 
                onClick={get_input_or_output_data}
                sx={{ marginRight: 5 }}
              >
                get input data
              </Button>
              )}
              {cardInfo.nodeData.handler_info && cardInfo.nodeData.handler_info.handler_args.processor_type == 'workflow' && (
                <Button 
                variant="contained" 
                onClick={enter_sub_workflow}
                >
                  enter
                </Button>
              )}
              {cardInfo.nodeData.completed && clickedNodeId == 'output' && (
                <Button 
                variant="contained" 
                onClick={get_input_or_output_data}
                >
                  get output data
                </Button>
              )}
            </CardContent>
          </Card>
        )}
      </div>
    );
}

export default WorkflowrunGraph;
