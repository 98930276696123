import { AppBar, Box, Container, ContainerProps, MenuItem, Toolbar, Typography, styled } from '@mui/material';
import Grid from '@mui/material/Grid';
import { Home, Logout, Bike, Run } from "mdi-material-ui";
import { useAuth } from "react-oidc-context";
import { Link, Outlet } from 'react-router-dom';
import FetchingIndicator from "./FetchingIndicator";


const AppBarButton = styled(MenuItem, {})({
    fontSize: '0.875rem',
    fontWeight: 500,
    textTransform: 'uppercase',
    letterSpacing: '0.0892857143em',
});

const Menu = [
    {
        text: 'Home',
        icon: Home,
        path: '/',
    },
    {
        text: 'WORKFLOW_APPLICATION',
        icon: Bike,
        path: '/workflowApplicationList',
    },
    {
        text: 'WORKFLOW_RUN',
        icon: Run,
        path: '/WorkflowRunQuery',
    },
]

export default function Root() {

    let menu = <></>;
    const auth = useAuth();
    Menu.forEach((item) => {
        menu = (
            <>
                {menu}

                <Link to={item.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <AppBarButton>
                        <item.icon fontSize='small' /><Box sx={{ display: { xs: 'none', sm: 'inline' } }}>&nbsp;{item.text}</Box>
                    </AppBarButton>
                </Link>
            </>);
    });
    const containerProps: ContainerProps = {
        maxWidth: 'xl',
        fixed: true,
    };
    const innerContainerProps: ContainerProps = {
        ...containerProps,
        disableGutters: true,
    };
    function signout() {
        auth.signoutRedirect();
    }
    return <>
        <FetchingIndicator />
        <AppBar
            position='sticky'
        >
            <Container {...innerContainerProps}>
                <Toolbar>
                    <Grid container width="100%">
                        <Grid display="flex" alignContent="center" alignItems="center">
                            <Link to='/' style={{ textDecoration: 'none', color: 'inherit' }}>
                                <MenuItem sx={
                                    {
                                        paddingLeft: 0
                                    }
                                }>
                                    <Typography fontSize="1.3rem" sx={{ display: { xs: 'none', sm: 'inline' } }}>
                                        &nbsp;ZHMLE Dashboard
                                    </Typography>
                                </MenuItem>
                            </Link>
                            {menu}
                        </Grid>
                        <Grid display="flex" marginLeft="auto">
                            <MenuItem onClick={signout}>
                                <Logout fontSize='small' />
                            </MenuItem>
                        </Grid>
                    </Grid>
                </Toolbar>
            </Container>
        </AppBar>
        <Container {...containerProps}>
            <Container sx={{
                marginTop: 2,
            }}  {...innerContainerProps} >
                <Outlet />
            </Container>
        </Container>
    </>
}