import 'reactflow/dist/style.css';
import { Box } from '@mui/material';
import WorkflowRunGraph from './WorkflowRunGraph';


function WorkflowRunFlowPage() {
    return (
      <Box sx={{ width: '100%', height: '100vh', bgcolor: 'background.default' }}>
         <WorkflowRunGraph />
      </Box>
    );
  }
  
export default WorkflowRunFlowPage;