import Grid from '@mui/material/Grid';

function NotFound() {
    return (
        <>
            <Grid>
                NotFound
            </Grid>
        </>
    )
}
export default NotFound;
