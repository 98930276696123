import React, { useState } from 'react';
import { 
  Button, 
  Dialog, 
  DialogActions, 
  DialogContent, 
  DialogContentText, 
  DialogTitle 
} from '@mui/material';

interface RegisterButtonProps {
  env: string;
  group: string;
  name: string;
  version: string;
  handRegister: (env: string, group: string, name: string, version: string) => void;
}

const RegisterButtonWithConfirmation: React.FC<RegisterButtonProps> = ({ env, group, name, version, handRegister }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    handRegister(env, group, name, version);
    // setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    handRegister(env, group, name, version);
    setOpen(false);
  };

  return (
    <>
      <Button onClick={handleClickOpen} variant="outlined" color="primary">
        register again
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Registration"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to register this item again?
            <br />
            Environment: {env}
            <br />
            Group: {group}
            <br />
            Name: {name}
            <br />
            Version: {version}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} color="primary" autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RegisterButtonWithConfirmation;