import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { Box, Typography, Paper , CardHeader} from '@mui/material';
import { styled } from '@mui/system';
import { ThemeProvider, createTheme } from '@mui/material/styles';

interface TreeNode {
  name: string;
  children?: TreeNode[];
  _children?: TreeNode[];
}

interface CustomHierarchyPointNode extends d3.HierarchyPointNode<TreeNode> {
  _children?: CustomHierarchyPointNode[];
  x0?: number;
  y0?: number;
}

interface DependencyTreeProps {
  data: Record<string, any>;
}

const StyledSvg = styled('svg')(({ theme }) => ({
  width: '100%',
  height: '100%',
  '& .link': {
    fill: 'none',
    stroke: theme.palette.grey?.[300] || '#e0e0e0',
    strokeWidth: 2,
  },
  '& .node circle': {
    fill: theme.palette.primary.main || '#1976d2',
    stroke: theme.palette.primary.dark || '#115293',
    strokeWidth: 2,
    cursor: 'pointer',
  },
  '& .node text': {
    font: '12px sans-serif',
    fill: theme.palette.text.primary || '#000000',
  },
}));

const DependencyTree: React.FC<DependencyTreeProps> = ({ data }) => {
  const svgRef = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (!svgRef.current) return;

    d3.select(svgRef.current).selectAll("*").remove();

    const root = d3.hierarchy(convertToTreeData(data)) as CustomHierarchyPointNode;

    const width = 1000;
    const height = 800;
    const margin = { top: 20, right: 90, bottom: 30, left: 90 };

    const svg = d3.select(svgRef.current)
      .attr('viewBox', `0 0 ${width} ${height}`)
      .append('g')
      .attr('transform', `translate(${margin.left},${margin.top})`);

    const tree = d3.tree<TreeNode>().size([height - margin.top - margin.bottom, width - margin.left - margin.right]);

    function click(event: any, d: CustomHierarchyPointNode) {
      if (d.children) {
        d._children = d.children;
        d.children = undefined;
      } else {
        d.children = d._children;
        d._children = undefined;
      }
      update(d);
    }

    root.x0 = height / 2;
    root.y0 = 0;
    update(root);

    function update(source: CustomHierarchyPointNode) {
      const treeData = tree(root);
      const nodes = treeData.descendants();
      const links = treeData.links();
      let i = 0;

      nodes.forEach((d) => { 
        d.y = d.depth * 180; 
        d.x = d.x || 0;
        d.y = d.y || 0;
      });

      const node = svg.selectAll('g.node')
        .data(nodes, (d: any) => d.id || (d.id = ++i));

      const nodeEnter = node.enter().append('g')
        .attr('class', 'node')
        .attr('transform', d => `translate(${source.y0 || 0},${source.x0 || 0})`)
        .on('click', click);

      nodeEnter.append('circle')
        .attr('r', 1e-6)
        .style('fill', (d: any) => d._children ? 'lightsteelblue' : '#fff');

      nodeEnter.append('text')
        .attr('dy', '.35em')
        .attr('x', (d: any) => d.children || d._children ? -13 : 13)
        .attr('text-anchor', (d: any) => d.children || d._children ? 'end' : 'start')
        .text((d: any) => d.data.name);

      const nodeUpdate = nodeEnter.merge(node as any);

      nodeUpdate.transition()
        .duration(750)
        .attr('transform', d => `translate(${d.y},${d.x})`);

      nodeUpdate.select('circle')
        .attr('r', 10)
        .style('fill', (d: any) => d._children ? 'lightsteelblue' : '#fff');

      const nodeExit = node.exit().transition()
        .duration(750)
        .attr('transform', d => `translate(${source.y || 0},${source.x || 0})`)
        .remove();

      nodeExit.select('circle')
        .attr('r', 1e-6);

      nodeExit.select('text')
        .style('fill-opacity', 1e-6);

      const link = svg.selectAll('path.link')
        .data(links, (d: any) => d.target.id);

      const linkEnter = link.enter().insert('path', 'g')
        .attr('class', 'link')
        .attr('d', d => {
          const o = { x: source.x0 || 0, y: source.y0 || 0 };
          return diagonal(o, o);
        });

      link.merge(linkEnter as any)
        .transition()
        .duration(750)
        .attr('d', d => diagonal(d.source, d.target));

      link.exit().transition()
        .duration(750)
        .attr('d', d => {
          const o = { x: source.x || 0, y: source.y || 0 };
          return diagonal(o, o);
        })
        .remove();

      nodes.forEach((d: CustomHierarchyPointNode) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }

    function diagonal(s: { x: number, y: number }, d: { x: number, y: number }) {
      return `M ${s.y} ${s.x}
              C ${(s.y + d.y) / 2} ${s.x},
                ${(s.y + d.y) / 2} ${d.x},
                ${d.y} ${d.x}`;
    }


    const zoom = d3.zoom<SVGSVGElement, unknown>()
      .scaleExtent([0.5, 5])
      .on('zoom', (event) => {
        svg.attr('transform', event.transform);
      });

    d3.select(svgRef.current).call(zoom);

  }, [data]);

  const convertToTreeData = (data: Record<string, any>): TreeNode => {
    const root: TreeNode = { name: Object.keys(data)[0], children: [] };
    const traverse = (node: TreeNode, obj: Record<string, any>) => {
      for (const [key, value] of Object.entries(obj[node.name])) {
        const child: TreeNode = { name: key };
        if (typeof value === 'object' && value !== null && Object.keys(value).length > 0) {
          child.children = [];
          traverse(child, { [key]: value });
        }
        node.children!.push(child);
      }
    };
    traverse(root, data);
    return root;
  };

  return (
    <StyledSvg ref={svgRef}></StyledSvg>
  );
};

export default function DependencyTreePage(data: any) {
  const theme = createTheme();
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ width: '100%', height: '100vh', bgcolor: 'background.default', display: 'flex', flexDirection: 'column' }}>
        <Paper elevation={3} sx={{ flexGrow: 1, overflow: 'hidden' }}>
          <CardHeader title='Dependency Tree' sx={{ bgcolor: 'grey.100' }} />
          <DependencyTree data={data['data']} />
        </Paper>
      </Box>
    </ThemeProvider>
  );
}