import { ReactNode, useEffect, useState } from 'react';
import { hasAuthParams, useAuth } from 'react-oidc-context';
import './App.css';

function Protected({ children }: { children: ReactNode }): JSX.Element {
  const auth = useAuth();
  const [hasTriedSignin, setHasTriedSignin] = useState(false);

  useEffect(() => {
    if (!hasAuthParams() &&
      !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading &&
      !hasTriedSignin
    ) {
      auth.signinRedirect();
      setHasTriedSignin(true);
    }
  }, [auth, hasTriedSignin]);

  if (!auth.isAuthenticated) {
    return <>You must <button onClick={() => void auth.signinRedirect()}>login in</button></>;
  } else {
    return children as JSX.Element;
  }
}

export default Protected;
