import React, { useState, useEffect } from 'react';
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { 
  Box, 
  Card, 
  CardContent, 
  CardHeader, 
  Typography, 
  Button, 
  Alert, 
  CircularProgress,
  ThemeProvider,
  createTheme,
  CssBaseline,
  TextField,
  MenuItem
} from '@mui/material';
import DependencyTreePage from './DependencyTreeV2';
import ReactJson from 'react-json-view';

interface JsonViewerProps {
  title: string;
  data: any;
  isLoading: boolean;
  error: string | null;
}

const JsonViewer: React.FC<JsonViewerProps> = ({ title, data, isLoading, error }) => (
  <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
    <CardHeader title={title} sx={{ bgcolor: 'grey.100' }} />
    <CardContent sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
      {error && (
        <Alert severity="error" sx={{ mb: 2 }}>
          {error}
        </Alert>
      )}
      {isLoading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          <CircularProgress />
        </Box>
      ) : (
        <pre style={{ margin: 0, height: '100%', overflow: 'auto' }}>
          <ReactJson src={data}/>
        </pre>
      )}
    </CardContent>
  </Card>
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

export default function ProcessorDetailNew() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const [env, type, group, name, version] = id!.split('/');
  const [isDependencyLoading, setIsDependencyLoading] = useState(true);
  const [dependencyData, setDependencyData] = useState(null);
  const { isLoading, error, data } = useQuery({
      queryKey: ['processors', 'detail', type, group, name, version],
      queryFn: async () => {
          const res = await fetch(`/api/v1/processor/${env}/${type}/${group}/${name}/${version}`, {
              headers: {
                  'Authorization': 'Bearer ' + auth.user!.access_token,
              }
          });
          if (!res.ok) {
              const txt = await res.text();
              throw new Error(`Failed to fetch processor (${txt})`);
          }
          return await res.json();
      },
      retry: 3,
  });

  useEffect(() => {
    const fetchDependencyData = async () => {
      if (data) {
        setIsDependencyLoading(true)
        try {
          const dependencyRes = await fetch(`/api/v1/get_dependency/${env}/${type}/${group}/${name}/${version}`, {
            headers: {
              'Authorization': `Bearer ${auth.user!.access_token}`,
            }
          });

          if (!dependencyRes.ok) {
            const txt = await dependencyRes.text();
            throw new Error(`Failed to fetch additional data: ${txt}`);
          }
          const fetchedData = await dependencyRes.json();
          setDependencyData(fetchedData);
          console.log(dependencyData);
        } catch (err) {
          console.error(err);
        }
      }
    };
    fetchDependencyData();
  }, [data]); // 当 data 变化时调用

  useEffect(() => {
    if (dependencyData) {
      console.log('Updated dependency data:', dependencyData);
      setIsDependencyLoading(false)
      // 在这里处理依赖数据，例如更新其他状态或调用函数
    }
  }, [dependencyData]); // 监听 dependencyData 的变化

  if (isLoading || isDependencyLoading) {
      return <>Loading...</>;
  } else if (error) {
      return <>Error: {error.message}</>;
  } else if (data == null) {
      return <>No data</>;
  }
  // const [data, setData] = useState<typeof sampleData | null>(null);
  // const [isLoading, setIsLoading] = useState(true);
  // setIsLoading(true);
  // const isLoading = true;

  const input_data = data.info?.basic?.inputs || {};
  const output_data = data.info?.basic?.outputs || {};
  const information = data.info?.basic?.description || {};
  const basic = data.info?.basic || {};
  // const [error, setError] = useState<string | null>(null);

  const goToEdit = () => {
    // Assuming the edit page URL structure. Adjust as needed.
    navigate(`/processorEdit?id=${env}/${type}/${group}/${name}/${version}`);
  };

  return (
    <div>
      <div>
        <TextField label="Type" value={basic.type} variant="outlined" fullWidth disabled />
        <TextField label="Group" value={basic.group} variant="outlined" fullWidth disabled />
        <TextField label="Name" value={basic.name} variant="outlined" fullWidth disabled />
        <TextField label="Version" value={basic.version} variant="outlined" fullWidth disabled />
      </div>
      <div>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', p: 3 ,padding: 0}}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
            </Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
              <JsonViewer 
                title="Inputs" 
                data={input_data} 
                isLoading={isLoading} 
                error={error}
              />
              <JsonViewer 
                title="Outputs" 
                data={output_data} 
                isLoading={isLoading} 
                error={error}
              />
              <JsonViewer 
                title="Information" 
                data={information}
                isLoading={isLoading}
                error={error}
              />
              <DependencyTreePage data={dependencyData} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Button 
                variant="contained" 
                onClick={goToEdit}
                sx={{ px: 4, py: 1.5, fontSize: '1.1rem' }}
              >
                Go To Edit
              </Button>
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
}