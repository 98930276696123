import React, { useState, useCallback, ChangeEvent } from 'react'
import { useDropzone } from 'react-dropzone'
import { 
  Box, 
  TextField, 
  Button, 
  Typography, 
  Paper, 
  Grid 
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useAuth } from "react-oidc-context";


type FileWithPreview = File & {
  preview: string;
}


export default function Uploadjsonform() {
    const auth = useAuth();
    const [file, setFile] = useState<FileWithPreview | null>(null); // 单个文件
    const [jsonInput, setJsonInput] = useState<string>(''); // 作为 JSON 字符串处理
    
    const onDrop = useCallback((acceptedFiles: File[]) => {
      if (acceptedFiles.length > 0) {
        const selectedFile = acceptedFiles[0];
        setFile(Object.assign(selectedFile, {
          preview: URL.createObjectURL(selectedFile)
        }));
      }
    }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  const handleUnPack = async () => {

    if (!file) {
      alert('No file selected');
      return;
    }
    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await fetch('/api/v1/data_analysis_pickle', {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + auth.user!.access_token,
          },
          body: formData,
      });

      // 检查响应状态
      if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

      // 获取响应的二进制内容
      const blob = await response.blob();
  
      // 创建下载链接并触发下载
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
  
      a.download = `${file.name}_data_analysis.zip`;
      document.body.appendChild(a);
      a.click();
  
      // 清理 URL 对象
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

    } catch (error) {
      console.error('Error:', error);
      alert(error)
    }
  }

  const handleSubmit = async () => {
    // console.log('Files:', file)
    // console.log('JSON Input:', jsonInput)

    if (!file) {
        alert('No file selected');
        return;
    }

    if (jsonInput == '') {
        alert('jsonInput is blank');
        return;
    }

    // const jsonString = typeof jsonInput === 'object' ? JSON.stringify(jsonInput) : jsonInput;
    const jsonString = JSON.stringify(jsonInput);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('json_input', jsonString);

    try {
        const response = await fetch('/api/v1/data_analysis', {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + auth.user!.access_token,
            },
            body: formData,
        });

        // 检查响应状态
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

        // 获取响应的二进制内容
        const blob = await response.blob();
    
        // 创建下载链接并触发下载
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
    
        a.download = `${file.name}_data_analysis.zip`;
        document.body.appendChild(a);
        a.click();
    
        // 清理 URL 对象
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);

    } catch (error) {
      console.error('Error:', error);
      alert(error)
    }
  }

//   const handleJsonChange = (content: { jsObject: any }) => {
//     // 将 jsObject 转换为 JSON 字符串
//     console.log("content:" + content)
//     setJsonInput(JSON.stringify(content.jsObject));
//   }

  const handleJsonChange = (content: { jsObject: any }) => {
      if (content?.jsObject) {
        // console.log("content:" + content)
        setJsonInput(JSON.stringify(content.jsObject));
      } else {
        console.warn('Invalid content structure:', content);
      }
   };

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper
            {...getRootProps()}
            sx={{
              p: 3,
              textAlign: 'center',
              cursor: 'pointer',
              backgroundColor: isDragActive ? 'action.hover' : 'background.paper',
              border: '2px dashed',
              borderColor: isDragActive ? 'primary.main' : 'grey.300'
            }}
          >
            <input {...getInputProps()} />
            <CloudUploadIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
            <Typography>
              {isDragActive
                ? 'Drop the files here ...'
                : "Drag 'n' drop some files here, or click to select files"}
            </Typography>
            {file && (
              <Typography variant="body2" sx={{ mt: 2 }}>
                file selected
              </Typography>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleUnPack}
          >
            unpack
          </Button>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            multiline
            rows={10}
            variant="outlined"
            label="JSON Input"
            value={jsonInput}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setJsonInput(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <Button 
            variant="contained" 
            color="primary" 
            onClick={handleSubmit}
            fullWidth
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </Box>
  )
}