import React from 'react'
import {useEffect, useState } from 'react';
import { 
  TextField, 
  InputAdornment, 
  IconButton, 
  Container, 
  Box, 
  Typography,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Button
} from '@mui/material'
import { useLocation, useNavigate } from "react-router-dom";
import { Search as SearchIcon, PlayArrow as PlayArrowIcon } from '@mui/icons-material'
import { ThemeProvider, createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
})

export default function WorkflowRunQuery() {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = React.useState('')
  const [env, setEnv] = React.useState('')
  const [envoptions, setEnvoptions] = React.useState<string[]>([]);

  useEffect(() => {
    // 使用 fetch 或其他方法从接口获取数据
    fetch("/api/v1/envs")
        .then(response => response.json())
        .then(data => {
          setEnvoptions(data);
          if (data.length > 0) {
            setEnv(data[0]);
          }
        })
        .catch(error => console.error('Error fetching data:', error));
  }, []);

  const handleStartWorkflow = () => {
    console.log('Starting new workflow')
    // 这里可以添加启动新工作流的逻辑
  }

  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault()
    console.log('Searching for:', searchTerm)
    if(env == ''){
      alert("env is blank")
      return;
    }
    if(searchTerm == ''){
      alert("workflow_run_id is blank");
      return;
    }
    navigate(`/workflowRunFlow?env=${env}&workflow_run_id=${searchTerm}`);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Typography variant="h4" component="h1" gutterBottom>
            Workflow Run Flow Query
          </Typography>
          <form onSubmit={handleSearch} style={{ width: '100%' }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl sx={{ minWidth: 120 }}>
                <InputLabel id="env-select-label">env</InputLabel>
                <Select
                  labelId="env-select-label"
                  value={env}
                  label="env"
                  onChange={(e) => setEnv(e.target.value)}
                  sx={{
                    borderRadius: '100px',
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderRadius: '100px',
                    },
                  }}
                >
                  {envoptions.map(option => (
                    <MenuItem key={option} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="输入Workflow_run_id..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton type="submit" aria-label="search">
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '0px',
                    '&.Mui-focused fieldset': {
                      borderColor: 'primary.main',
                    },
                  },
                  '& .MuiInputBase-input': {
                    transition: theme.transitions.create(['border-color', 'box-shadow']),
                    '&:focus': {
                      boxShadow: `${theme.palette.primary.main} 0 0 0 0.2rem`,
                    },
                  },
                }}
              />
            </Box>
          </form>
        </Box>
      </Container>
    </ThemeProvider>
  )
}