import { Box, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button
  } from '@mui/material';
import { styled } from '@mui/material/styles';
import { checkTimeValidate } from './UnisvcDeployDetails';

function TypeSelect(props: { labelId: string, id: string, label: string, onChange: (e: SelectChangeEvent) => void, value: string }) {
    return <Select
        {...props}
    >
        <MenuItem value={'service'}>Service</MenuItem>
        <MenuItem value={'workflow'}>Workflow</MenuItem>
        <MenuItem value={''}>All</MenuItem>
    </Select>
}

function VisibilityTypeSelect(props: { labelId: string, id: string, label: string, onChange: (e: SelectChangeEvent) => void, value: string }) {
    return <Select
        {...props}
    >
        <MenuItem value={'deleted'}>deleted</MenuItem>
        <MenuItem value={'missing'}>missing</MenuItem>
        <MenuItem value={'normal'}>normal</MenuItem>
        <MenuItem value={''}>All</MenuItem>
    </Select>
}

function EnvSelect(props: { labelId: string, id: string, label: string, onChange: (e: SelectChangeEvent) => void, value: string }) {

    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        // 使用 fetch 或其他方法从接口获取数据
        fetch("/api/v1/envs")
            .then(response => response.json())
            .then(data => setOptions(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <Select
            {...props}
        >
            <MenuItem value={''}>All</MenuItem>
            {options.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
        </Select>
    );
}

function Home() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);

    const env = params.get('env');
    const type = params.get('type');
    const group = params.get('group');
    const name = params.get('name');
    const version = params.get('version');
    const page = parseInt(params.get('page') || '0');
    const pageSize = parseInt(params.get('pageSize') || '20');
    const visibility = params.get('visibility');
    const [open, setOpen] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [clickedRow, setClickedRow] = useState<any>();
    const [clickedRowK8sConfig, setclickedRowK8sConfig] = useState<any>();
    useEffect(() => {
      if (clickedRow) {
        queryK8sConfig();
      }
    }, [clickedRow]);

    const [factor, setFactor] = useState('');
    const [max, setMax] = useState('');
    const [min, setMin] = useState('');
    const [secondaryConfirmation, setSecondaryConfirmation] = useState('');

    const auth = useAuth();
    let dataSection: ReactNode = <></>;
    let k8s_config_dialog: ReactNode = <></>;
    let delete_alert_dialog: ReactNode = <></>;
    let total = 0;

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        '&.MuiTableCell-head': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        '&.MuiTableCell-body': {
          fontSize: 14,
          padding: '4px 16px', // 减小主体单元格的内边距
        },
      }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:last-child td, &:last-child th': {
        border: 0,
      },
      height: '40px', // 设置行高为 40px，您可以根据需要调整这个值
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
        cursor: 'pointer',
      },
    }));

    // not need to mess with this
    const { isPending, error, data } = useQuery({
        queryKey: ['processors', 'list', env, type, group, name, version, visibility, page, pageSize],
        queryFn: async () => {
            const searchParams_: { [key: string]: string } = {
                page: '' + page,
                pageSize: '' + pageSize,
            };
            if (env) {
                searchParams_['env'] = env;
            }
            if (type) {
                searchParams_['type'] = type;
            }
            if (group) {
                searchParams_['group'] = group;
            }
            if (name) {
                searchParams_['name'] = name;
            }
            if (version) {
                searchParams_['version'] = version;
            }
            if (visibility) {
                searchParams_['visibility'] = visibility;
            }
            const searchParams = new URLSearchParams(searchParams_);
            const res = await fetch('/api/v1/processors?' + searchParams.toString(), {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            });
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to fetch processor list (${txt})`);
            }
            return await res.json();
        },
        retry: 3,
    });
    if (isPending) {
        dataSection = <>Loading</>
    } else if (error) {
        dataSection = <>Failed to load, reason: {'' + error}</>
    } else {
        total = data.total;
        const processors = data.processors;
        // add new columns here
        const table = {
            'Env': {
                'label': 'Env',
                'valueFn': (p: any) => p.basic.env,
            },
            'Type': {
                'label': 'Type',
                'valueFn': (p: any) => p.basic.type,
            },
            'Group': {
                'label': 'Group',
                'valueFn': (p: any) => p.basic.group,
            },
            'Name': {
                'label': 'Name',
                'valueFn': (p: any) => p.basic.name,
            },
            'Version': {
                'label': 'Version',
                'valueFn': (p: any) => p.basic.version,
            },
            'Visiblity': {
                'label': 'Visiblity',
                'valueFn': (p: any) => p.basic.visibility,
            },
            // 'K8s Name': {
            //     'label': 'K8s Name',
            //     'valueFn': (p: any) => p.k8s?.name,
            // },
            'Deployment Id': {
                'label': 'Deployment Id',
                'valueFn': (p: any) => p.deploy_infos[0]?.deployment_id,
            },
            'Deployment Run Id': {
                'label': 'Deployment Run Id',
                'valueFn': (p: any) => {
                    for (let i = 0; i < p.deploy_infos.length; i++) {
                        if (p.deploy_infos[i]?.running) {
                            return p.deploy_infos[i]?.deployment_run_id;
                        }
                    }
                },
            },
            'K8s replicas': {
                'label': 'K8s replicas',
                'valueFn': (p: any) => p.k8s ? (p.k8s?.ready_replicas + "/" + p.k8s?.replicas) : null,
            }
        }
        const makeRow = (p: any) => {
            const id = p.env + '/' + p.basic.type + '/' + p.basic.group + '/' + p.basic.name + '/' + p.basic.version;
            let deploy_info = <></>
            if (p.deploy_infos.length > 0) {
                deploy_info = <Button variant="outlined" style={{ textAlign: 'left' }}
                    onClick={() => {
                        const url = `/unisvc/deploy?id=${id}`;
                        window.open(url, '_blank');
                    }}
                >View</Button>
            }
            let k8s_replicas = <></>
            if (p.k8s) {
                k8s_replicas = <Button onClick={() => handle_click_k8s_replicas(p)} variant="text" style={{ textAlign: 'left' }}>{(table as any)['K8s replicas'].valueFn(p)}</Button>
            }
            return <StyledTableRow key={id}>
                {Object.keys(table).map((k) => {
                    if( k !== 'K8s replicas'){
                        return <StyledTableCell key={k} style={{ textAlign: 'left' }}>{(table as any)[k].valueFn(p)}</StyledTableCell>
                    }
                })}
                <StyledTableCell >
                    {k8s_replicas}
                </StyledTableCell>
                <StyledTableCell >
                    {/* <Button href={'/processor?id=' + id} target="_blank" variant="outlined" style={{ textAlign: 'left' }}>View</Button> */}
                    <Button variant="outlined" style={{ textAlign: 'left' }}
                        onClick={() => {
                            const url = `/processor?id=${id}`;
                            window.open(url, '_blank');
                        }}
                    >View</Button>
                </StyledTableCell>
                <StyledTableCell>
                    {deploy_info}
                </StyledTableCell>
                <StyledTableCell >
                    <Button variant="outlined" style={{ textAlign: 'left' }}
                        onClick={() => {
                            const url = `/workflow/new_run?id=${id}`;
                            window.open(url, '_blank');
                        }}
                    >Run</Button>
                </StyledTableCell>
                <StyledTableCell >
                    <Button variant="outlined" style={{ textAlign: 'left' }}
                        onClick={() => {
                          handle_delete(p)
                        }}
                    >Del</Button>
                </StyledTableCell>
            </StyledTableRow>
        }

        // dataSection = (<table>
        //     <thead>
        //         <tr>
        //             {Object.keys(table).map((k) => {
        //                 return <th key={k} style={{ textAlign: 'left' }}>{(table as any)[k].label}</th>
        //             })}
        //             <th style={{ textAlign: 'left' }}>Basic Info</th>
        //             <th style={{ textAlign: 'left' }}>Deploy Info</th>
        //         </tr>
        //     </thead>
        //     <tbody>
        //         {processors.map(makeRow)}
        //     </tbody>
        // </table>)

        dataSection = (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="service table">
                        <TableHead>
                            <TableRow>
                                {Object.keys(table).map((k) => {
                                    return <StyledTableCell key={k} style={{ textAlign: 'left' }}>{(table as any)[k].label}</StyledTableCell>
                                })}
                                <StyledTableCell style={{ textAlign: 'left' }}>Basic Info</StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>Deploy Info</StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>New Run</StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>Delete</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {processors.map(makeRow)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )
    }

    const queryK8sConfig = async() => {
      try{
        const requestData = {
          type: clickedRow?.type,
          group: clickedRow?.group,
          name: clickedRow?.name,
          version: clickedRow?.version,
          env: clickedRow?.env
        };
      
        const response = await fetch('/api/v1/k8s_replicas/data', {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + auth.user!.access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        })
        const data = await response.json();
        setclickedRowK8sConfig(data);
        console.log('Success:', data);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    const handle_click_k8s_replicas = (p:any)=>{
        setClickedRow(p.k8s);
        setOpen(true);
    }

    const handle_delete = (p:any)=>{
        console.log("123")
        setClickedRow(p.basic);
        setOpenDelete(true);
    }

    const submitDelete = () => {
      if (!checkTimeValidate(clickedRow?.env)){
        return;
      }
      if (secondaryConfirmation !== clickedRow?.name){
        alert("你输入的内容与该unisvc/workflow的name不一致");
        return;
      }
      const requestData = {
        type: clickedRow?.type,
        group: clickedRow?.group,
        name: clickedRow?.name,
        version: clickedRow?.version,
        env: clickedRow?.env
      };
  
      // 发起请求
      fetch(`/api/v1/unisvc/delete`, {
        method: 'POST',
        headers: {
          'Authorization': 'Bearer ' + auth.user!.access_token,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (!response.ok){
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .then((data) => {
          setOpenDelete(false);
          setSecondaryConfirmation('');
          alert("delete success!")
        })
        .catch((error) => {
          alert("delete fail!")
          console.error('Error:', error);
        });
    }

    const handleSubmitK8sConfig = () => {
        const requestData = {
          factor,
          max,
          min,
          type: clickedRow?.type,
          group: clickedRow?.group,
          name: clickedRow?.name,
          version: clickedRow?.version,
          env: clickedRow?.env
        };
    
        // 发起请求
        fetch('/api/v1/k8s_replicas/edit', {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + auth.user!.access_token,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        })
          .then((response) => response.json())
          .then((data) => {
            // console.log('Success:', data);
            setOpen(false);
            alert("set success")
          })
          .catch((error) => {
            alert("set fail")
            console.error('Error:', error);
          });
    };

    k8s_config_dialog =  (
        clickedRow && clickedRowK8sConfig && (
          <Dialog open={open} onClose={() => setOpen(false)}>
            <DialogTitle>设置 K8s Replicas</DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div>
                  <strong>Type:</strong> {clickedRow.type || 'N/A'}
                </div>
                <div>
                  <strong>Group:</strong> {clickedRow.group || 'N/A'}
                </div>
                <div>
                  <strong>Name:</strong> {clickedRow.name || 'N/A'}
                </div>
                <div>
                  <strong>Version:</strong> {clickedRow.version || 'N/A'}
                </div>
                <strong>Factor:</strong>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  placeholder={`目前的factor: ${clickedRowK8sConfig.factor}`}
                  value={factor}
                  onChange={(e) => setFactor(e.target.value)}
                />
                <strong>Max:</strong>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  placeholder={`目前的max: ${clickedRowK8sConfig.max}`}
                  value={max}
                  onChange={(e) => setMax(e.target.value)}
                />
                <strong>Min:</strong>
                <TextField
                  size="small"
                  margin="dense"
                  fullWidth
                  variant="outlined"
                  placeholder={`目前的min: ${clickedRowK8sConfig.min}`}
                  value={min}
                  onChange={(e) => setMin(e.target.value)}
                />
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleSubmitK8sConfig} color="primary">
                确认
              </Button>
              <Button onClick={() => {
                  setOpen(false);
                  setclickedRowK8sConfig(null);
                }
                } color="info">
                取消
              </Button>
            </DialogActions>
          </Dialog>
        )
      );

    
    delete_alert_dialog =  (
      clickedRow && (
        <Dialog open={openDelete} onClose={() => setOpenDelete(false)}>
          <DialogTitle>确认操作</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div>
                <strong>Type:</strong> {clickedRow.type || 'N/A'}
              </div>
              <div>
                <strong>Group:</strong> {clickedRow.group || 'N/A'}
              </div>
              <div>
                <strong>Name:</strong> {clickedRow.name || 'N/A'}
              </div>
              <div>
                <strong>Version:</strong> {clickedRow.version || 'N/A'}
              </div>
              <strong>confirm:</strong>
              <TextField
                size="small"
                margin="dense"
                fullWidth
                variant="outlined"
                placeholder="unisvc 或 工作流的 name"
                value={secondaryConfirmation}
                onChange={(e) => setSecondaryConfirmation(e.target.value)}
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={submitDelete} color="primary">
              确认
            </Button>
            <Button onClick={() => {
              setOpenDelete(false);
              setSecondaryConfirmation('')}
              } color="info">
              取消
            </Button>
          </DialogActions>
        </Dialog>
      )
    );  

    const totalPages = Math.ceil(total / pageSize);
    let pageNumbersToShow: Set<number> = new Set();
    pageNumbersToShow.add(0);
    pageNumbersToShow.add(totalPages - 1);
    for (let i = page - 2; i <= page + 2; i++) {
        pageNumbersToShow.add(i);
    }
    let pageNumbers = Array.from(pageNumbersToShow).filter((v) => v >= 0 && v < totalPages);
    pageNumbers.sort((a, b) => a - b);
    function toPage(page: number) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', '' + page);
        searchParams.set('pageSize', '' + pageSize);
        navigate(location.pathname + '?' + searchParams.toString());
    }
    let prevN = -1;
    let i = 0;
    let paginationItems: ReactNode[] = [];
    function _makePItem(txt: string, page?: number) {
        i += 1;
        if (page === undefined) {
            return <span key={i} style={{
                margin: '0.5em',
            }}>{txt}</span>;
        } else {
            return <a key={i} onClick={() => toPage(page)} href="#" style={
                {
                    margin: '0.5em',
                }
            }>{txt}</a>;
        }
    }
    pageNumbers.map((pn) => {
        if (pn - prevN > 1) {
            paginationItems.push(_makePItem("..."));
        }
        if (pn === page) {
            paginationItems.push(_makePItem('[' + (pn + 1) + ']'));
        }
        else {
            paginationItems.push(_makePItem('' + (pn + 1), pn));
        }
        prevN = pn;
    });

    // function _makeControl<T>(id: string, value: string, label: string, type: T, field: string) {
    //     const Type: any = type;
    //     return <>
    //         <InputLabel id={id + "-label"}>{label}</InputLabel>
    //         <Type
    //             labelId={id + "-label"}
    //             id={id}
    //             value={value}
    //             onChange={(e: any) => {
    //                 const v = e.target.value;
    //                 const searchParams = new URLSearchParams(location.search);
    //                 if (v) {
    //                     searchParams.set(field, '' + v);
    //                 } else {
    //                     searchParams.delete(field);
    //                 }
    //                 navigate(location.pathname + '?' + searchParams.toString());
    //             }}
    //         />
    //     </>
    // }

    function _makeControl<T>(id: string, value: string, label: string, type: T, field: string) {
        const Type: any = type;
        return (
          <Box sx={{ minWidth: 463, maxWidth: 463, mr: 4, mb: 1 }}>
            <InputLabel id={id + "-label"}>{label}</InputLabel>
            <Type
              labelId={id + "-label"}
              id={id}
              value={value}
              size="small"
              fullWidth
              onChange={(e: any) => {
                const v = e.target.value;
                const searchParams = new URLSearchParams(location.search);
                if (v) {
                  searchParams.set(field, '' + v);
                } else {
                  searchParams.delete(field);
                }
                navigate(location.pathname + '?' + searchParams.toString());
              }}
            />
          </Box>
        );
      }

    // return (
    //     <>
    //         <Stack>
    //             <Stack sx={{
    //                 marginBottom: 2,
    //             }}>
    //                 {_makeControl('select-env', env || '', 'Env', EnvSelect, 'env')}
    //                 {_makeControl('select-type', type || '', 'Type', TypeSelect, 'type')}
    //                 {_makeControl('input-group', group || '', 'Group', Input, 'group')}
    //                 {_makeControl('input-name', name || '', 'Name', Input, 'name')}
    //                 {_makeControl('input-version', version || '', 'Version', Input, 'version')}
    //                 {_makeControl('input-visiblity', visibility || '', 'Visibility', VisibilityTypeSelect, 'visibility')}

    //             </Stack>
    //             {dataSection}
    //             <Box sx={{
    //                 marginTop: 1,
    //                 alignSelf: 'center',
    //             }}>
    //                 {paginationItems}
    //             </Box>
    //         </Stack>
    //     </>
    // )


    return (
        <Box sx={{ maxWidth: '100%', overflow: 'hidden' }}>
          <Stack direction="row" flexWrap="wrap" sx={{ mb: 2 }}>
            {_makeControl('select-env', env || '', 'Env', EnvSelect, 'env')}
            {_makeControl('select-type', type || '', 'Type', TypeSelect, 'type')}
            {_makeControl('input-visibility', visibility || '', 'Visibility', VisibilityTypeSelect, 'visibility')}
          </Stack>

          <Stack direction="row" flexWrap="wrap" sx={{ mb: 2 }}>
            {_makeControl('input-group', group || '', 'Group', Input, 'group')}
            {_makeControl('input-name', name || '', 'Name', Input, 'name')}
            {_makeControl('input-version', version || '', 'Version', Input, 'version')}
          </Stack>

          <Paper sx={{ width: '100%', overflow: 'hidden' }}>
            {dataSection}
          </Paper>

          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
            {paginationItems}
          </Box>
          {k8s_config_dialog}
          {delete_alert_dialog}
        </Box>
      );



}
export default Home;
