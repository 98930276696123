import { Box, Input, InputLabel, MenuItem, Select, SelectChangeEvent, Stack } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { ReactNode, useEffect, useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button
  } from '@mui/material';
import { styled } from '@mui/material/styles';
import RegisterButtonWithConfirmation from './RegisterButton';

function EnvSelect(props: { labelId: string, id: string, label: string, onChange: (e: SelectChangeEvent) => void, value: string }) {

    const [options, setOptions] = useState<string[]>([]);

    useEffect(() => {
        // 使用 fetch 或其他方法从接口获取数据
        fetch("/api/v1/envs")
            .then(response => response.json())
            .then(data => setOptions(data))
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    return (
        <Select
            {...props}
        >
            {options.map(option => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
            ))}
        </Select>
    );
}


function RegistedSelect(props: { labelId: string, id: string, label: string, onChange: (e: SelectChangeEvent) => void, value: string }) {

    return (
        <Select
            {...props}
        >
            <MenuItem key="True" value="True">True</MenuItem>
            <MenuItem key="False" value="False">False</MenuItem>
        </Select>
    );
}

function WorkflowApplicationListPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const params = new URLSearchParams(location.search);

    const env = params.get('env');
    const group = params.get('group');
    const name = params.get('name');
    const version = params.get('version');
    const registed = params.get('registed');
    const page = parseInt(params.get('page') || '0');
    const pageSize = parseInt(params.get('pageSize') || '20');

    const auth = useAuth();
    let dataSection: ReactNode = <></>;
    let total = 0;

    const handRegister = (env: string, group: string, name:string, version: string) => {
        if (window.confirm('Are you sure you want to register again?')) {
            fetch(`/api/v1/register_workflow/${env}/${group}/${name}/${version}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + auth.user!.access_token,
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Register failed');
                    }
                    return response.json();
                })
                .then(data => {
                    alert(JSON.stringify(data));
                })
                .catch(error => {
                    alert(`There was a problem with the register operation: ${error.message}`);
                    console.error('There was a problem with the register operation:', error);
                });
        }
    };

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        '&.MuiTableCell-head': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        '&.MuiTableCell-body': {
          fontSize: 14,
          padding: '4px 16px', // 减小主体单元格的内边距
        },
      }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:last-child td, &:last-child th': {
        border: 0,
      },
      height: '40px', // 设置行高为 40px，您可以根据需要调整这个值
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
        cursor: 'pointer',
      },
    }));

    // not need to mess with this
    const { isPending, error, data } = useQuery({
        queryKey: ['processors', 'list', env, group, name, version, registed, page, pageSize],
        queryFn: async () => {
            const searchParams_: { [key: string]: string } = {
                page: '' + page,
                pageSize: '' + pageSize,
            };
            if (env) {
                searchParams_['env'] = env;
            }
            if (group) {
                searchParams_['group'] = group;
            }
            if (name) {
                searchParams_['name'] = name;
            }
            if (version) {
                searchParams_['version'] = version;
            }
            if (registed) {
                searchParams_['registed'] = registed;
            }
            const searchParams = new URLSearchParams(searchParams_);
            const res = await fetch('/api/v1/get_workflow_application?' + searchParams.toString(), {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            });
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to fetch processor list (${txt})`);
            }
            return await res.json();
        },
        retry: 3,
    });
    if (isPending) {
        dataSection = <>Loading</>
    } else if (error) {
        dataSection = <>Failed to load, reason: {'' + error}</>
    } else {
        total = data.total;
        // add new columns here
        const table = {
            'Env': {
                'label': 'Env',
                'valueFn': (p: any) => p.env,
            },
            'Group': {
                'label': 'Group',
                'valueFn': (p: any) => p.group,
            },
            'Name': {
                'label': 'Name',
                'valueFn': (p: any) => p.name,
            },
            'Version': {
                'label': 'Version',
                'valueFn': (p: any) => p.version,
            },
            'registed': {
                'label': 'registed',
                'valueFn': (p: any) => p.registed,
            },
        }
        const makeRow = (p: any) => {
            const id = p.env + '/' + p.group + '/' + p.name + '/' + p.version;
            let deploy_info = <></>
            return <StyledTableRow key={id}>
                {Object.keys(table).map((k) => {
                    return <StyledTableCell key={k} style={{ textAlign: 'left' }}>{(table as any)[k].valueFn(p)}</StyledTableCell>
                })}
                <StyledTableCell>
                    <Button href={'/viewWorkflowApplication?id=' + id} variant="outlined" color="primary">View</Button>
                </StyledTableCell>
                {/* <StyledTableCell >
                    <Button onClick={() => handRegister(p.env, p.group, p.name, p.version)} variant="outlined" color="primary">register again</Button>
                </StyledTableCell> */}
                <StyledTableCell>
                    <RegisterButtonWithConfirmation env={p.env} group={p.group} name={p.name} version={p.version} handRegister={handRegister}/>
                </StyledTableCell>
            </StyledTableRow>
        }

        dataSection = (
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="service table">
                        <TableHead>
                            <TableRow>
                                {Object.keys(table).map((k) => {
                                    return <StyledTableCell key={k} style={{ textAlign: 'left' }}>{(table as any)[k].label}</StyledTableCell>
                                })}
                                <StyledTableCell style={{ textAlign: 'left' }}>view</StyledTableCell>
                                <StyledTableCell style={{ textAlign: 'left' }}>register again</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.workflow_list.map(makeRow)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        )
    }

    const totalPages = Math.ceil(total / pageSize);
    let pageNumbersToShow: Set<number> = new Set();
    pageNumbersToShow.add(0);
    pageNumbersToShow.add(totalPages - 1);
    for (let i = page - 2; i <= page + 2; i++) {
        pageNumbersToShow.add(i);
    }
    let pageNumbers = Array.from(pageNumbersToShow).filter((v) => v >= 0 && v < totalPages);
    pageNumbers.sort((a, b) => a - b);
    function toPage(page: number) {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('page', '' + page);
        searchParams.set('pageSize', '' + pageSize);
        navigate(location.pathname + '?' + searchParams.toString());
    }
    let prevN = -1;
    let i = 0;
    let paginationItems: ReactNode[] = [];
    function _makePItem(txt: string, page?: number) {
        i += 1;
        if (page === undefined) {
            return <span key={i} style={{
                margin: '0.5em',
            }}>{txt}</span>;
        } else {
            return <a key={i} onClick={() => toPage(page)} href="#" style={
                {
                    margin: '0.5em',
                }
            }>{txt}</a>;
        }
    }
    pageNumbers.map((pn) => {
        if (pn - prevN > 1) {
            paginationItems.push(_makePItem("..."));
        }
        if (pn === page) {
            paginationItems.push(_makePItem('[' + (pn + 1) + ']'));
        }
        else {
            paginationItems.push(_makePItem('' + (pn + 1), pn));
        }
        prevN = pn;
    });

    function _makeControl<T>(id: string, value: string, label: string, type: T, field: string) {
        const Type: any = type;
        return (
          <Box sx={{ minWidth: 265, maxWidth: 265, mr: 4, mb: 1 }}>
            <InputLabel id={id + "-label"}>{label}</InputLabel>
            <Type
              labelId={id + "-label"}
              id={id}
              value={value}
              size="small"
              fullWidth
              onChange={(e: any) => {
                const v = e.target.value;
                const searchParams = new URLSearchParams(location.search);
                if (v) {
                  searchParams.set(field, '' + v);
                } else {
                  searchParams.delete(field);
                }
                navigate(location.pathname + '?' + searchParams.toString());
              }}
            />
          </Box>
        );
      }

    return (
        <>
            <Stack>
                <Stack direction="row" flexWrap="wrap" sx={{
                    marginBottom: 2,
                }}>
                    {_makeControl('select-env', env || '', 'Env', EnvSelect, 'env')}
                    {_makeControl('input-group', group || '', 'Group', Input, 'group')}
                    {_makeControl('input-name', name || '', 'Name', Input, 'name')}
                    {_makeControl('input-version', version || '', 'Version', Input, 'version')}
                    {_makeControl('select-registed', registed || '', 'Registed', RegistedSelect, 'registed')}
                </Stack>
                {dataSection}
                <Box sx={{
                    marginTop: 1,
                    alignSelf: 'center',
                }}>
                    {paginationItems}
                </Box>
            </Stack>
        </>
    )
}
export default WorkflowApplicationListPage;
