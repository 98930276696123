import React, { useState, useEffect } from 'react';
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { 
  Box, 
  Card, 
  CardContent, 
  CardHeader, 
  Typography, 
  Button, 
  Alert, 
  CircularProgress,
  ThemeProvider,
  createTheme,
  CssBaseline,
  TextField,
  MenuItem
} from '@mui/material';

interface JsonViewerProps {
  title: string;
  data: any;
  isLoading: boolean;
  error: string | null;
}

const JsonViewer: React.FC<JsonViewerProps> = ({ title, data, isLoading, error }) => {
    const formatValue = (value: any): string => {
        if (typeof value === 'string') {
          // Replace escaped newline characters with actual line breaks
          return value.replace(/\\n/g, '\n');
        }
        return JSON.stringify(value, null, 2);
    };

    const renderJsonObject = (obj: any, indent: number = 0): JSX.Element[] => {
        return Object.entries(obj).map(([key, value], index) => (
          <Box key={index} sx={{ ml: indent * 2 }}>
            <Typography component="span" fontWeight="bold">{key}: </Typography>
            {typeof value === 'object' && value !== null ? (
              <Box>
                {renderJsonObject(value, indent + 1)}
              </Box>
            ) : (
              <Typography 
                component="span" 
                sx={{ 
                  whiteSpace: 'pre-wrap',
                  wordBreak: 'break-word'
                }}
              >
                {formatValue(value)}
              </Typography>
            )}
          </Box>
        ));
    };
    
    return (
        <Card sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
          <CardHeader title={title} sx={{ bgcolor: 'grey.100' }} />
          <CardContent sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                {error}
              </Alert>
            )}
            {isLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
              </Box>
            ) : (
                <Box sx={{ fontFamily: 'monospace' }}>
                    {renderJsonObject(data)}
              </Box>
            )}
          </CardContent>
        </Card>
    );
};

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2',
    },
  },
});

export default function WorkflowApplicationDetail() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const [env, group, name, version] = id!.split('/');
  const { isLoading, error, data } = useQuery({
      queryKey: ['processors', 'detail', group, name, version],
      queryFn: async () => {
            const res = await fetch(`/api/v1/workflow_application_detail/${env}/${group}/${name}/${version}`, {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            });
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to fetch processor (${txt})`);
            }
            return await res.json();
      },
      retry: 3,
  });
  if (isLoading) {
      return <>Loading...</>;
  } else if (error) {
      return <>Error: {error.message}</>;
  } else if (data == null) {
      return <>No data</>;
  }

  const goToEdit = () => {
    // Assuming the edit page URL structure. Adjust as needed.
    navigate(`/processorEdit?id=${env}/${group}/${name}/${version}`);
  };

  return (
    <div>
      <div>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', p: 3 ,padding: 0}}>
            <Typography variant="h4" component="h1" gutterBottom align="center">
            </Typography>
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', gap: 2, mb: 2 }}>
              <JsonViewer 
                title="WorkflowDefinition" 
                data={data} 
                isLoading={isLoading} 
                error={error}
              />
            </Box>
          </Box>
        </ThemeProvider>
      </div>
    </div>
  );
}