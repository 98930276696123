import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { User } from 'oidc-client-ts';
import { AuthProvider } from 'react-oidc-context';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import './App.css';
import { useConfig } from './ConfigContext';
import Home from './Home';
import NotFound from './NotFound';
import Protected from './Protected';
import Root from './Root';
import ProcessorDetailNew from './ProcessorDetailNew';
import WorkflowApplicationListPage from './WorkflowApplicationList';
import WorkflowApplicationDetail from './WorkflowApplicationDetail';
import ProcessorDetails from './ProcessorDetails';
import DependencyTreePageV2 from './DependencyTreeV2';
import UnisvcDeployDetails from './UnisvcDeployDetails';
import WorkflowRunFlowPage from './WorkflowRunFlowPage';
import WorkflowRunQuery from './WorkflowRunQuery';
import WorkflowNewRunPage from './WorkflowNewRunPage'
import Uploadjsonform from './UploadJsonForm'

const routes = createRoutesFromElements(
  <Route path='/' element={<Root />}>
    <Route path="" element={<Home />} />
    <Route path="processor" element={<ProcessorDetailNew />} />
    <Route path="processorEdit" element={<ProcessorDetails />} />
    <Route path="viewWorkflowApplication" element={<WorkflowApplicationDetail />} />
    <Route path="unisvc/deploy" element={<UnisvcDeployDetails />} />
    <Route path="workflowApplicationList" element={<WorkflowApplicationListPage />} />
    <Route path="dependencyTreePage" element={<DependencyTreePageV2 />} />
    {/* <Route path="workflowRun/graph" element={<WorkflowRunGraph />} /> */}
    <Route path="workflowRunFlow" element={<WorkflowRunFlowPage />} />
    <Route path="WorkflowRunQuery" element={<WorkflowRunQuery />} />
    <Route path="/workflow/new_run" element={<WorkflowNewRunPage />} />
    <Route path="*" element={<NotFound />} />
    <Route path="/Uploadjsonform" element={<Uploadjsonform />} />
  </Route>
);
const router = createBrowserRouter(routes, {});
const queryClient = new QueryClient();

function App() {
  var config = useConfig().config;
  const onSigninCallback = (_user: User | void): void => {
    window.history.replaceState(
      {},
      document.title,
      window.location.pathname
    )
  }
  if (!config) {
    return <div>Loading...</div>;
  } else {
    const oidcConfig = {
      authority: config.oidcAuthority,
      client_id: config.oidcClientId,
      redirect_uri: config.oirdRedirectUri,
      automaticSilentRenew: true,
      checkSessionIntervalInSeconds: 10,
      onSigninCallback,
    };

    return <AuthProvider {...oidcConfig}>
      <QueryClientProvider client={queryClient}>
        <Protected>
          <RouterProvider router={router} />
        </Protected>
      </QueryClientProvider>
    </AuthProvider>
  }
}

export default App;
