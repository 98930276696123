import React, { useState } from 'react'
import {
  Button,
  TextField,
  Typography,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Box,
  IconButton,
  FormControlLabel,
  Radio, 
  RadioGroup,
  FormControl
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { useQuery } from "@tanstack/react-query";
import { useAuth } from "react-oidc-context";
import { useLocation, useNavigate } from "react-router-dom";
import GenerateCallScript from './GenerateCallScript';


var globalEnv = ''
var globalAuth:any

const uploadFileToOSS = async (file: File): Promise<string> => {
  try {
    const formData = new FormData();
    formData.append('file', file);

    const response = await fetch('/api/v1/uploadToOss?env=' + globalEnv, {
      method: 'POST',
      headers: {
        'Authorization': 'Bearer ' + globalAuth.user!.access_token,
      },
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Failed to upload file to OSS');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    console.error('Error uploading file:', error);
    throw error;
  }
};


const InputField = ({ type, value, onChange}: { type: string, value: any, onChange: (value: any) => void}) => {
    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (file) {
        try {
          // 上传文件到 OSS
          const url = await uploadFileToOSS(file);
          // 将返回的 URL 更新到父组件的 formData 中
          onChange(url);
        } catch (error) {
          console.error('Failed to upload file:', error);
        }
      }
    };

    switch (type) {
      case 'string':
        return <TextField fullWidth value={value || ''} onChange={(e) => onChange(e.target.value)} />;
      case 'integer':
      case 'number':
        return <TextField fullWidth type="number" value={value || ''} onChange={(e) => onChange(parseInt(e.target.value, 10))} />;
      case 'boolean':
        return  (
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={value ? 'true' : 'false'}
                onChange={(e) => onChange(e.target.value === 'true')}
              >
                <FormControlLabel value="true" control={<Radio />} label="True" />
                <FormControlLabel value="false" control={<Radio />} label="False" />
              </RadioGroup>
            </FormControl>
          );
      case 'mesh':
      case 'binary':
      case 'image':
        return (
          <TextField
            fullWidth
            type="file"
            onChange={handleFileChange}
            InputLabelProps={{ shrink: true }}
          />
        )
      case 'tensor':
        return <TextField fullWidth multiline rows={4} value={value} onChange={(e) => onChange(e.target.value)} />
      default:
        return null
    }
  }
  

const DictField = ({ schema, value, onChange }: { schema: any, value: any, onChange: (value: any) => void }) => {
  const [entries, setEntries] = useState(Object.entries(value || {}))

  const handleAdd = () => {
    setEntries([...entries, ['', '']])
  }

  const handleRemove = (index: number) => {
    setEntries(entries.filter((_, i) => i !== index))
  }

  const handleChange = (index: number, key: string, newValue: any) => {
    const newEntries = [...entries]
    newEntries[index] = [key, newValue]
    setEntries(newEntries)
    onChange(Object.fromEntries(newEntries))
  }

  return (
    <Box>
      {entries.map(([key, val], index) => (
        <Grid container spacing={2} key={index} alignItems="center">
          <Grid item xs={5}>
            <InputField type={schema.$dict.key} value={key} onChange={(newKey) => handleChange(index, newKey, val)} />
          </Grid>
          <Grid item xs={5}>
            <InputField type={schema.$dict.value} value={val} onChange={(newVal) => handleChange(index, key, newVal)} />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => handleRemove(index)} color="error">
              <RemoveIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button startIcon={<AddIcon />} onClick={handleAdd} variant="outlined" sx={{ mt: 2 }}>
        Add Entry
      </Button>
    </Box>
  )
}

const ListField = ({ schema, value, onChange }: { schema: any, value: any, onChange: (value: any) => void }) => {
  const [items, setItems] = useState<string[]>(value || []);

  const handleAdd = () => {
    setItems([...items, ''])
  }

  const handleRemove = (index: number) => {
    setItems(items.filter((_, i: number) => i !== index));
    onChange(items.filter((_, i: number) => i !== index)); // 更新父组件的值
  }

  const handleChange = (index: number, newValue: any) => {
    const newItems = [...items]
    newItems[index] = newValue
    setItems(newItems)
    onChange(newItems)
  }

  return (
    <Box>
      {items.map((item: string, index: number) => (
        <Grid container spacing={2} key={index} alignItems="center">
          <Grid item xs={10}>
            <InputField type={schema.$list} value={item} onChange={(newValue) => handleChange(index, newValue)} />
          </Grid>
          <Grid item xs={2}>
            <IconButton onClick={() => handleRemove(index)} color="error">
              <RemoveIcon />
            </IconButton>
          </Grid>
        </Grid>
      ))}
      <Button startIcon={<AddIcon />} onClick={handleAdd} variant="outlined" sx={{ mt: 2 }}>
        Add Item
      </Button>
    </Box>
  )
}


const StructField = ({ schema, value, onChange, isChild = false , isParentOptional }: { schema: any, value: any, onChange: (value: any) => void, isChild?: boolean, isParentOptional?: boolean }) => {
    isParentOptional = isParentOptional || (schema.$optional !== undefined);
    return (
        <Card variant="outlined" sx={{ mb: 2, backgroundColor: isChild ? '#f9f9f9' : '#ffffff', borderRadius: 2, boxShadow: isChild ? '0 1px 3px rgba(0,0,0,0.1)' : 'none' }}>
        <CardContent>
          {Object.entries(schema).map(([key, fieldSchema]: [string, any]) => (
            <Box key={key} sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 500, color: 'text.secondary' }}>
                {key}
              </Typography>
              <FormField schema={fieldSchema} value={value?.[key]} onChange={(newValue) => onChange({ ...value, [key]: newValue })} isChild={true} isParentOptional={isParentOptional} />
            </Box>
          ))}
        </CardContent>
      </Card>
    );
};


const FormField = ({schema, value, onChange, isChild = false, isParentOptional = false }: {schema: any, value: any, onChange: (value: any) => void, isChild?: boolean, isParentOptional?: boolean }) => {    // 判断是否为必填字段：没有 $optional 表示必填
    const isRequired = !schema.$optional && !isParentOptional;
    isParentOptional = !isRequired;
    if (schema.$optional) {
      schema = schema.$optional;
    }
  
    if (typeof schema === 'string') {
      return (
        <Box sx={{ ml: isChild ? 2 : 0, borderLeft: isChild ? '2px solid #ddd' : 'none', pl: isChild ? 2 : 0, position: 'relative' }}>  {/* 根据是否是子字段来确定缩进 */}
          <Typography variant="subtitle1" component="label" sx={{ color: isRequired ? 'error.main' : 'text.primary' }}>
            {isRequired && <span style={{ color: 'red' }}>*</span>}
          </Typography>
          <InputField type={schema} value={value} onChange={onChange} />
        </Box>
      );
    } else if (schema.$dict) {
      return (
        <Box sx={{ ml: isChild ? 2 : 0, borderLeft: isChild ? '2px solid #ddd' : 'none', pl: isChild ? 2 : 0, position: 'relative' }}> {/* 子字段缩进 */}
          {isRequired && <span style={{ color: 'red' }}>*</span>}
          <DictField schema={schema} value={value} onChange={onChange} />
        </Box>
      );
    } else if (schema.$list) {
      return (
        <Box sx={{ ml: isChild ? 2 : 0, borderLeft: isChild ? '2px solid #ddd' : 'none', pl: isChild ? 2 : 0, position: 'relative' }}>
          {isRequired && <span style={{ color: 'red' }}>*</span>}
          <ListField schema={schema} value={value} onChange={onChange} />
        </Box>
      );
    } else if (schema.$struct) {
      return (
        <StructField schema={schema.$struct} value={value} onChange={onChange} isChild={true} isParentOptional={isParentOptional}/>
      );
    } else if (schema.$tensor) {
      return (
        <Box sx={{ ml: isChild ? 2 : 0, borderLeft: isChild ? '2px solid #ddd' : 'none', pl: isChild ? 2 : 0, position: 'relative' }}>
          {isRequired && <span style={{ color: 'red' }}>*</span>}
          <InputField type="tensor" value={value} onChange={onChange} />
        </Box>
      );
    }
  
    return null;
};



export default function WorkflowNewRunPage() {
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({})

  const [open, setOpen] = useState(false);
  const [includeDownloadUrn, setIncludeDownloadUrn] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    // const formDataToSend = new FormData();
    var formDataToSend: any = {}
    formDataToSend['input_data'] = formData;
    formDataToSend['spec_group'] = group;
    formDataToSend['spec_name'] = name;
    formDataToSend['spec_version'] = version;

    // console.log(formDataToSend)

    // formDataToSend.append('json_data', JSON.stringify(formData)); // 这里的 formData 包含嵌套结构和文件占位符

    try {
        const response = await fetch('/api/v1/workflow/run?env=' + globalEnv, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + auth.user!.access_token,
          },
          body: JSON.stringify(formDataToSend),
        });
    
        if (!response.ok) {
          
          throw new Error('Network response was not ok');
        }
    
        const result = await response.json();
        alert(result.run_id)
    } catch (error) {
        alert(error)
        console.error('Error submitting form:', error);
    }
  }

  // const generateCallScript = async (e: React.FormEvent) => {
  //   e.preventDefault(); // 防止默认行为，如表单提交
  //   const userConfirmed = window.confirm("是否下载 URN？");
  //   try {
  //     var formDataToSend: any = {}
  //     formDataToSend['spec_type'] = type;
  //     formDataToSend['spec_group'] = group;
  //     formDataToSend['spec_name'] = name;
  //     formDataToSend['spec_version'] = version;

  //     if (userConfirmed) {
  //       formDataToSend['download_urn'] = true;
  //     }

  //     const response = await fetch('/api/v1/generate/callScript?env=' + globalEnv, {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': 'Bearer ' + auth.user!.access_token,
  //       },
  //       body: JSON.stringify(formDataToSend),
  //     });

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const blob = await response.blob(); // 将响应数据转换为 Blob
  //     const url = window.URL.createObjectURL(blob);
  //     const a = document.createElement('a');
  //     a.href = url;
  //     a.download = group + "_" + name + "_" + version + "_run.py";
  //     document.body.appendChild(a); // 将 <a> 元素附加到文档
  //     a.click();
  //     a.remove();
  //     window.URL.revokeObjectURL(url);
  //   } catch (error) {
  //       alert(error)
  //       console.error('Error submitting form:', error);
  //   }
  // }

  const generateCallScript = async (includeDownloadUrn: boolean) => {
    try {
      var formDataToSend: any = {}
      formDataToSend['spec_type'] = type;
      formDataToSend['spec_group'] = group;
      formDataToSend['spec_name'] = name;
      formDataToSend['spec_version'] = version;

      // 如果用户选择下载 URN，则添加该参数
      if (includeDownloadUrn) {
        formDataToSend['download_urn'] = true;
      }

      const response = await fetch('/api/v1/generate/callScript?env=' + globalEnv, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + auth.user!.access_token,
        },
        body: JSON.stringify(formDataToSend),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }

      const blob = await response.blob(); // 将响应数据转换为 Blob
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = formDataToSend.spec_group + "_" + formDataToSend.spec_name + "_" + formDataToSend.spec_version + "_run.py";
      document.body.appendChild(a);
      a.click();
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      alert('Error: ' + error);
      console.error('Error submitting form:', error);
    }
  };
  
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const [env, type, group, name, version] = id!.split('/');

  globalEnv = env
  globalAuth = auth

  const { isLoading, error, data } = useQuery({
      queryKey: ['processors', 'detail', type, group, name, version],
      queryFn: async () => {
          const res = await fetch(`/api/v1/processor/${env}/${type}/${group}/${name}/${version}`, {
              headers: {
                  'Authorization': 'Bearer ' + auth.user!.access_token,
              }
          });
          if (!res.ok) {
              const txt = await res.text();
              throw new Error(`Failed to fetch processor (${txt})`);
          }
          return await res.json();
      },
      retry: 3,
  });

  if (isLoading) {
    return <>Loading...</>;
  } else if (error) {
      return <>Error: {error.message}</>;
  } else if (data == null) {
      return <>No data</>;
  }
  
  const schema = data.info?.basic?.inputs || {};

  return (
    <Card sx={{ maxWidth: 1000, margin: 'auto' }}>
      <CardHeader title="Input Data Form" />
      <Grid item xs={12} style={{ paddingLeft: '24px' }}>
        {/* <Button onClick={generateCallScript} variant="contained" color="primary">
          生成调用脚本
        </Button> */}
        <GenerateCallScript generateCallScript={generateCallScript} />
      </Grid>
      <CardContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            {Object.entries(schema).map(([key, fieldSchema]) => (
              <Grid item xs={12} key={key}>
                <Typography variant="subtitle1" sx={{ marginTop: '20px' }}>{key}</Typography>
                <FormField
                  key={key}
                  schema={fieldSchema}
                  value={formData[key as keyof typeof formData]}
                  onChange={(value) => setFormData({ ...formData, [key]: value })}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button type="submit" variant="contained" color="primary">
                RUN
              </Button>
            </Grid>
          </Grid>
        </form>
      </CardContent>
    </Card>
  )
}