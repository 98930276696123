import { Button, Stack, TextField, Grid, } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { ReactNode, useState } from 'react';
import { useAuth } from "react-oidc-context";
import { useLocation } from "react-router-dom";
import {Table, TableBody, TableCell,TableContainer,TableHead,TableRow,Paper, Typography, Box} from "@mui/material";
import { styled } from '@mui/material/styles';

function UnisvcDeployDetails() {

    const auth = useAuth();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const id = params.get('id');
    const [env, type, group, name, version] = id!.split('/');

    const { isPending, error, data } = useQuery({
        queryKey: ['unisvc', 'deploy_info', env, type, group, name, version],
        queryFn: async () => {
            const res = await fetch(`/api/v1/unisvc/deploy_info/${env}/${type}/${group}/${name}/${version}`, {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            });
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to get unsivc deploy info (${txt})`);
            }
            return await res.json();
        },
        retry: 3,
    });
    const { isPending: envIsPending, error: envError, data: envData } = useQuery({
        queryKey: [],
        queryFn: async () => {
            const res = await fetch(`/api/v1/envs`, {
                headers: {
                    'Authorization': 'Bearer ' + auth.user!.access_token,
                }
            })
            if (!res.ok) {
                const txt = await res.text();
                throw new Error(`Failed to get envs: (${txt})`);
            }
            return await res.json();
        },
        retry: 3,
    });

    const [selectedEnv, setSelectedEnv] = useState<string>('');
    const [selectedDeploymentRunId, setSelectedDeploymentRunId] = useState<string>('');

    let dataSection: ReactNode = <></>

    if (isPending) {
        return <div>Loading...</div>;
    } else if (error) {
        return <div>Error: {error.message}</div>;
    } else if (data == null) {
        return <>No data</>;
    }

    const { deploy_infos } = data[env];


    let deploySection = <></>
    let deployToNonDevEnv = <></>
    let envs: string[] = [];

    if (envIsPending || envError || envData == null) {
        envs = [];
    } else {
        envs = envData;
    }

    const table = {
        'Deployment Run ID':
        {
            'label': 'Deployment Run ID',
            'valueFn': (p: any) => p.deployment_run_id,
        },
        'Created At':
        {
            'label': 'Created At',
            'valueFn': (p: any) => p.create_time,
        },
        'Git Commit':
        {
            'label': 'Git Commit',
            'valueFn': (p: any) => p.git_commit_id.substring(0, 7),
        },
        'Code Hash':
        {
            'label': 'Code Hash',
            'valueFn': (p: any) => p.code_hash.substring(0, 7),
        },
        'Zhmle Package':
        {
            'label': 'Zhmle Package',
            'valueFn': (p: any) => p.zhmle_package,
        },
        'Running':
        {
            'label': 'Running',
            'valueFn': (p: any) => String(p.running),
        }
    }

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        '&.MuiTableCell-head': {
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.common.white,
        },
        '&.MuiTableCell-body': {
          fontSize: 14,
          padding: '4px 16px', // 减小主体单元格的内边距
        },
      }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
      '&:last-child td, &:last-child th': {
        border: 0,
      },
      height: '40px', // 设置行高为 40px，您可以根据需要调整这个值
      '&:hover': {
        backgroundColor: theme.palette.action.selected,
        cursor: 'pointer',
      },
    }));

    const checkTimeValidate = (env: string) => {
        if (env === '') env = "zh-prod";
        let currentHour = new Date().getUTCHours() + 8;
        if (currentHour >= 24) currentHour -= 24;
        if ((env !== 'zh-dev' && env !== 'zh-uat') && (currentHour >= 6)) {
            alert('this operate for prod environments is only allowed between 12:00 AM and 6:00 AM.');
            return false;
        }else{
            return true;
        }
    }

    const handleRedeploy = (env: string, deployment_run_id: any) => {

        if (window.confirm('Are you sure you want to redeploy?') && checkTimeValidate(env)) {
            fetch(`/api/v1/unisvc/redeploy/${env}/${deployment_run_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + auth.user!.access_token,
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Redeploy failed');
                    }
                    return response.json();
                })
                .then(data => {
                    alert(JSON.stringify(data));
                })
                .catch(error => {
                    alert(`There was a problem with the redeploy operation: ${error.message}`);
                    console.error('There was a problem with the redeploy operation:', error);
                });
        }
    };

    const handlerDeploy = (env: string, deployment_id: any) => {
        if (window.confirm('Are you sure you want to create a new deployment run?')) {
            fetch(`/api/v1/unisvc/deploy/${env}/${deployment_id}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + auth.user!.access_token,
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Deploy failed');
                    }
                    return response.json();
                })
                .then(data => {
                    alert(JSON.stringify(data));
                })
                .catch(error => {
                    alert(`There was a problem with the deploy operation: ${error.message}`);
                    console.error('There was a problem with the deploy operation:', error);
                });
        }
    };

    const handleDeployToNonDevEnv = () => {
        if (window.confirm('Are you sure you want to deploy?') && checkTimeValidate(selectedEnv)) {
            fetch(`/api/v1/unisvc/deploy_to/${selectedEnv}?dev_deployment_run_id=${selectedDeploymentRunId}`,
                {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + auth.user!.access_token,
                    }
                })
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Deploy to non dev env failed, selectedEnv: ${selectedEnv}, selectedDeploymentRunId: ${selectedDeploymentRunId}`);
                    }
                    return response.json();
                })
                .then(data => {
                    alert(JSON.stringify(data));
                })
                .catch(error => {
                    alert(`There was a problem with the deploy to non-dev env operation: ${error.message}`);
                    console.error('There was a problem with the deploy to non-dev env operation:', error);
                });
        }
    };

    const makeRow = (p: any) => {
        const id = env + '/' + p.deployment_run_id;
        return <StyledTableRow key={id}>
            {Object.keys(table).map((k) => {
                return <StyledTableCell key={k} style={{ textAlign: 'left' }}>{(table as any)[k].valueFn(p)}</StyledTableCell>
            })}
            <StyledTableCell >
                <Button variant="outlined" onClick={() => handleRedeploy(p.env, p.deployment_run_id)}>Redeploy</Button>
            </StyledTableCell>
        </StyledTableRow>
    }

    const Deploy_info_list = (p: any) => {
        const env_key = p.p;
        return  <>
            <div style={{ margin : 8, fontWeight: 500, letterSpacing: 0.7, fontSize: 20}}> {env_key} </div>
            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer>
                    <Table stickyHeader aria-label="service table">
                        <TableHead>
                            <TableRow>
                                {Object.keys(table).map((k) => {
                                    return <StyledTableCell key={k} style={{ textAlign: 'left' }}>{(table as any)[k].label}</StyledTableCell>
                                })}
                                <StyledTableCell style={{ textAlign: 'left' }}>Action</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data[env_key]['deploy_infos'].map(makeRow)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </>
    }

    // dataSection = (
    // <Paper sx={{ width: '100%', overflow: 'hidden' }}>
    //     <TableContainer>
    //         <Table stickyHeader aria-label="service table">
    //             <TableHead>
    //                 <TableRow>
    //                     {Object.keys(table).map((k) => {
    //                         return <StyledTableCell key={k} style={{ textAlign: 'left' }}>{(table as any)[k].label}</StyledTableCell>
    //                     })}
    //                     <StyledTableCell style={{ textAlign: 'left' }}>Action</StyledTableCell>
    //                 </TableRow>
    //             </TableHead>
    //             <TableBody>
    //                 {deploy_infos.map(makeRow)}
    //             </TableBody>
    //         </Table>
    //     </TableContainer>
    // </Paper>)
    const deploymentRunIdOptions = deploy_infos.map((deploy_info: any) => {
        return <option value={deploy_info.deployment_run_id}>{deploy_info.deployment_run_id}</option>
    }
    );

    const envsOptions = envs.filter((env: any) => !env.endsWith("dev")).map((env: any) => {
        return <option value={env}>{env}</option>
    }
    );


    if (env.toLowerCase().endsWith('dev')) {
        deploySection = (
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={() => handlerDeploy(env, deploy_infos[0]?.deployment_id)}>Create New Deployment Run</Button>
            </Grid>
        )
        deployToNonDevEnv = (
            <Grid container spacing={1} alignItems="center">
                <Grid item >
                    <TextField value={"Deploy dev workflow run"} disabled />
                </Grid>
                <Grid item >
                    <TextField
                        select
                        label="workflow run id"
                        variant="outlined"
                        SelectProps={{ native: true }}
                        value={selectedDeploymentRunId}
                        onChange={(e) => setSelectedDeploymentRunId(e.target.value)}
                        style={{ width: 120 }}
                        InputLabelProps={{
                            shrink: true, // 让 label 始终显示在左上角
                        }}
                    >
                        <option value="">None</option>
                        {deploymentRunIdOptions}
                    </TextField>
                </Grid>
                <Grid item>
                    <TextField value={"to env "} disabled />
                </Grid>

                <Grid item>
                    <TextField
                        select
                        label="env"
                        variant="outlined"
                        SelectProps={{ native: true }}
                        value={selectedEnv}
                        onChange={(e) => setSelectedEnv(e.target.value)}
                        style={{ width: 120 }}
                        InputLabelProps={{
                            shrink: true, // 让 label 始终显示在左上角
                        }}
                    >
                        <option value="">None</option>
                        {envsOptions}
                    </TextField>
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => handleDeployToNonDevEnv()}>
                        Deploy to non-dev env
                    </Button>
                </Grid>
            </Grid>
        )
    }


    return (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={12}> <TextField label="Env" value={env} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Group" value={group} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Name" value={name} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Version" value={version} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Deployment Id" value={deploy_infos[0]?.deployment_id} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Git Repo" value={deploy_infos[0]?.git_repo} variant="outlined" fullWidth disabled /></Grid>
                <Grid item xs={12}> <TextField label="Git Revision" value={deploy_infos[0]?.git_revision} variant="outlined" fullWidth disabled /></Grid>
                {deploySection}
            </Grid>

            {deployToNonDevEnv}
            <>
                <div style={{ margin : 8}}></div>
            </>
            {/* <Stack>
                {dataSection}
            </Stack>
            <>
                <div style={{ margin : 8}}>Other envs</div>
            </>
            <Stack>
                {dataSection}
            </Stack>
            <>
                <div style={{ margin : 8}}></div>
            </>
            <Stack>
                {dataSection}
            </Stack> */}


            {Object.keys(data).map((k) => {
                return <Deploy_info_list p={k} />
            })}

        </div>
    );
}

export default UnisvcDeployDetails;