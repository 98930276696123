export type FieldType = "input" | "output" | "description" | "title";

export type DescState = {
    changed: boolean,
    value: string,
    oriValue: string,
}

export type MultiLangDescState = {
    [key: string]: DescState
}

export type FieldDesc = {
    key: string,
    keyDisplay: string,
    data: MultiLangDescState,
    obsolete: boolean,
    type: FieldType,
    spec_type: any
}

export type DescData = {
    title: FieldDesc,
    description: FieldDesc,
    inputs: FieldDesc[],
    outputs: FieldDesc[],
}


export function getFieldTypeKey(field: string): {
    fieldType: FieldType,
    fieldKey: string,
} {
    let firstSlash = field.indexOf('/');
    if (firstSlash === -1) {
        throw new Error(`invalid field ${field}`);
    }
    let fieldType = field.slice(0, firstSlash);
    let fieldKey = field.slice(firstSlash + 1);
    if (fieldType !== 'input' && fieldType !== 'output' && fieldType !== 'description' && fieldType !== 'title') {
        throw new Error(`invalid field type ${fieldType}`);
    }
    return { fieldType, fieldKey };
}
