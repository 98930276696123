import React, { forwardRef, useImperativeHandle, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import MDEditor from '@uiw/react-md-editor';


// 导出 Markdown 文件的函数
const exportMarkdownFile = (content: string, filename: string) => {
    const blob = new Blob([content], { type: 'text/markdown;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    URL.revokeObjectURL(url);
};


const MarkdownComponent = forwardRef(({ mdFileParamsDict }: { mdFileParamsDict: any }, ref) => {
    // const markdownContent = generateMarkdown(field_desc);

    let fieldDescState = mdFileParamsDict['fieldDescState']
    let queryParams = mdFileParamsDict['queryParams']

    

    // 生成 Markdown 文本的函数
    const generateInitMarkdown = (): string => {
        let markdown = `# ${fieldDescState['title']['data']['CHN']['value']}\n\n`;
        markdown += `${fieldDescState['description']['data']['CHN']['value']}\n\n`;
        markdown += `## 输入定义\n\n`;
        markdown += `输入的JSON schema:\n\n`;
        markdown += '```json\n';
        markdown += `${JSON.stringify(mdFileParamsDict['inputs'], null, 2)}\n`;
        markdown += '```\n\n';
        markdown += `输入样例:\n\n`;
        markdown += '```json\n';
        markdown += `${fieldDescState['inputs'][fieldDescState['inputs'].length - 1]['data']['CHN']['value']}\n`;
        markdown += '```\n\n';
        markdown += '输入说明：\n\n';
        let inputDescribe = fieldDescState['inputs'];
        for (let i = 0; i < inputDescribe.length - 1; i++) {
            let keyDisplay = inputDescribe[i]['keyDisplay'].replace(/^\.+|\.+$/g, '');
            markdown += '- ' + `${keyDisplay}:` + ` ${inputDescribe[i]['data']['CHN']['value']}\n`;
        };
        markdown += '\n';

        markdown += `## 输出定义\n\n`;
        markdown += `输入的JSON schema:\n\n`;
        markdown += '```json\n';
        markdown += `${JSON.stringify(mdFileParamsDict['outputs'], null, 2)}\n`;
        markdown += '```\n\n';
        markdown += `输出样例:\n\n`;
        markdown += '```json\n';
        markdown += `${fieldDescState['outputs'][fieldDescState['outputs'].length - 1]['data']['CHN']['value']}\n`;
        markdown += '```\n\n';
        markdown += '输出说明：\n\n';
        let outputDescribe = fieldDescState['outputs'];
        for (let i = 0; i < outputDescribe.length - 1; i++) {
            let keyDisplay = outputDescribe[i]['keyDisplay'].replace(/^\.+|\.+$/g, '');
            markdown += '- ' + `${keyDisplay}:` + ` ${outputDescribe[i]['data']['CHN']['value']}\n`;
        };
        markdown += '\n';
        return markdown;
    };

    let markdownContent = ''

    if(mdFileParamsDict["openType"] == "showSaveVersion"){
        markdownContent = mdFileParamsDict['PreviousMdVersion'] ;
    }else{
        markdownContent = generateInitMarkdown();
    }

    const [value, setValue] = useState<string>(markdownContent);

    const handleExportClick = () => {
        exportMarkdownFile(value, 'document.md'); // 导出文件名为 document.md
    };

    const handleSaveExport = async () => {

        const response = await fetch(`/api/v1/field/save_field`, {
            method: 'POST',
            headers: {
              'Authorization': 'Bearer ' + mdFileParamsDict['auth'].user!.access_token,
            },
            body: JSON.stringify({
                env : queryParams['env'],
                type :  queryParams['type'],
                group : queryParams['group'],
                name : queryParams['name'],
                version : queryParams['version'],
                field: "PreviousMdVersion",
                value: value,
            }),
        });

        if (!response.ok) {
            alert("save md file failed!")
        }
        else{
            alert("save md file success!")
        }
    };

    // 暴露导出方法给父组件
    useImperativeHandle(ref, () => ({
        handleExportClick,
        handleSaveExport
    }));

    return (
        // <div>
        //     <ReactMarkdown remarkPlugins={[remarkGfm]}>{markdownContent}</ReactMarkdown>
        //     {/* <button onClick={handleExportClick}>导出 Markdown 文件</button> */}
        // </div>

        <div data-color-mode="light">
            <MDEditor
              value={value}
              onChange={(v) => setValue(v || "")}
              preview="edit"
              height={400}
            />
        </div>
    );
});

export default MarkdownComponent;
