import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

interface GenerateCallScriptProps {
    generateCallScript: (shouldDownloadUrn: boolean) => void;
}

const GenerateCallScript: React.FC<GenerateCallScriptProps> = ({ generateCallScript })=> {
  const [open, setOpen] = useState(false);
  const [includeDownloadUrn, setIncludeDownloadUrn] = useState(false);

  const handleButtonClick = () => {
    setOpen(true);
  };

  const handleConfirm = (choice: string) => {
    setOpen(false);

    if (choice === 'cancel') {
      return;
    }

    const shouldDownloadUrn = choice === 'yes';
    generateCallScript(shouldDownloadUrn);
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleButtonClick}>
        生成脚本
      </Button>

      <Dialog
        open={open}
        onClose={() => handleConfirm('cancel')}
      >
        <DialogTitle>确认操作</DialogTitle>
        <DialogContent>
          <DialogContentText>
            是否下载 URN？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleConfirm('yes')} color="primary">
            是
          </Button>
          <Button onClick={() => handleConfirm('no')} color="secondary">
            否
          </Button>
          <Button onClick={() => handleConfirm('cancel')} color="info">
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default GenerateCallScript;
