import React, { useState, useRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Grid } from '@mui/material';
import MarkdownComponent from './MarkdownComponent'

function ShowMdFileDialog({ mdFileParamsDict }: { mdFileParamsDict: any }) {
    const [open, setOpen] = useState(false);
    const [mdFileParamsDictState, setMdFileParamsDictState] = useState<any>(mdFileParamsDict);
    const markdownRef = useRef<{ 
        handleExportClick: () => void;
        handleSaveExport: () => void; 
    } | null>(null); // 创建 ref


    const handleFetch = async () => {
        const updatedParams = { ...mdFileParamsDictState, openType: "showInitVersion" };
        setMdFileParamsDictState(updatedParams);
        // mdFileParamsDict["openType"] = "showInitVersion"
        setOpen(true); // 打开对话框显示数据
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleExport = () => {
        if (window.confirm('Are you sure you want to export md file?')) {
            if (markdownRef.current) {
                markdownRef.current.handleExportClick(); // 调用子组件的导出方法
            }
        }
    };

    const saveExport = () => {
        if (window.confirm('Are you sure you want to save?')) {
            if (markdownRef.current) {
                markdownRef.current.handleSaveExport();
            }
        }
    };

    const handleLoadLastVersionMdFile = async () => {
        try {
            // mdFileParamsDict["openType"] = "showSaveVersion";
            const queryParams = mdFileParamsDict['queryParams'];
            const response = await fetch(`/api/v1/md_file/get_field_info`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${mdFileParamsDict['auth'].user!.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    env: queryParams['env'],
                    type: queryParams['type'],
                    group: queryParams['group'],
                    name: queryParams['name'],
                    version: queryParams['version'],
                    field: "PreviousMdVersion",
                }),
            });
            const fieldValue = await response.json();
            const updatedParams = { ...mdFileParamsDictState, openType: "showSaveVersion", PreviousMdVersion: fieldValue};
            setMdFileParamsDictState(updatedParams);
            // mdFileParamsDict["PreviousMdVersion"] = fieldValue;
            setOpen(true);
        } catch (error) {
            console.error("Error loading last saved MD file:", error);
        }
    };
    

    
    return (
        <div>
            <Grid 
              container 
              spacing={2} 
              justifyContent="center" 
              alignItems="center" 
              style={{ height: '10vh' }}
            >
              <Grid item>
                <Button variant="contained" onClick={handleFetch}>
                  GENERATE MD FILE
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" onClick={handleLoadLastVersionMdFile}>
                  LOAD LAST SAVED MD FILE
                </Button>
              </Grid>
            </Grid>

            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
                <DialogTitle>MD file Preview</DialogTitle>
                <DialogContent>
                    <MarkdownComponent ref={markdownRef} mdFileParamsDict={mdFileParamsDictState}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={saveExport} color="secondary">
                        save
                    </Button>
                    <Button onClick={handleExport} color="secondary">
                        Export Markdown
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default ShowMdFileDialog;
